import CursorText from "components/CursorText";
import ReactPlayer from "react-player";
import "yet-another-react-lightbox/styles.css";
import { useState } from "react";

function DynamicVideobox({ imageUrl, videoUrl, onEnded, playing, onPlayClick }) {
  const [isMuted, setIsMuted] = useState(true); // Initially muted for autoplay

  const handlePlay = () => {
    onPlayClick(); // Trigger parent play function
    setIsMuted(false); // Unmute after play starts
  };

  return (
    <div
      style={{
        position: "relative",
        width: "100%",
        height: "100%",
        overflow: "hidden"
      }}
    >
      {!playing && <CursorText />}

      {/* Custom Thumbnail - Click to Play */}
      {!playing && (
        <img
          src={imageUrl}
          alt="Video Thumbnail"
          style={{
            width: "100%",
            height: "auto",
            position: "absolute",
            top: 0,
            left: 0,
            cursor: "none",
            zIndex: 1,
          }}
          onClick={handlePlay}
        />
      )}

      {/* Preloaded Video - Visible on Play */}
      {videoUrl ? (
        <div
          className="player-container"
          style={{
            // opacity: playing ? 1 : 0,
            transition: "opacity 0.5s ease",
          }}
        >
          <div className="player-container">
            <div className="player-wrapper-small">
              <ReactPlayer
                className="react-player"
                url={videoUrl?.length > 10 ? videoUrl : `https://vimeo.com/${videoUrl}`}
                // url={ 'https://player.vimeo.com/progressive_redirect/playback/1024683493/rendition/720p/file.mp4?loc=external&signature=04c7267208ad00aa4d3b55027ab5b69f632ab0cd6f1e9deb01659bc63f22a477'}
                playing={playing} // Plays video when `playing` is true
                muted={isMuted} // Initially muted for autoplay
                controls
                width="100%"
                height="100%"
                volume={isMuted ? 0 : 1}
                onEnded={onEnded}
              />
            </div>
          </div>
        </div>
      ) : null}

    </div>
  );
}

export default DynamicVideobox;
