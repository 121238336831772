import React from 'react'
import Skeleton from 'react-loading-skeleton';

function WorkPageSkeleton() {
    return (
        <div style={{ width: "100%", }}>
            <div style={{ display: "flex", justifyContent: "center", marginBottom: '20px' }} className='skeleton-wrap'>
                <Skeleton width={150} height={20} />
            </div>
            <div style={{ display: "flex", justifyContent: "center", marginBottom: '40px' }} className='skeleton-wrap'>
                <Skeleton width={300} height={40} />
            </div>
            <div style={{ flex: "1", minWidth: "300px" }} className='skeleton-wrap'>
                <Skeleton height={300} width="100%" />
            </div>
        </div>
    )
}

export default WorkPageSkeleton