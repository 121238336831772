import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "assets/css/locomotive-scroll.css";
import Layout from "pages/Layout";
import NotFound from "pages/NotFound";
import "./assets/scss/main.scss";
import Home from "pages/Home";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Works from "pages/Works";
import WorkPage from "pages/WorkPage";
import Jobs from "pages/Jobs";
import News from "pages/News";
import Initiatives from "pages/Initiatives";
import Contact from "pages/Contact";
import Culture from "pages/Culture";
import Thinking from "pages/Thinking";
import "yet-another-react-lightbox/styles.css";
import ScrollToTop from "components/ScrollToTop";
import OfflinePage from "pages/OfflinePage";
import { Online, Offline } from "react-detect-offline";
import People from "pages/People";
import Dooj from "pages/Dooj";
import BlinkEcom from "pages/BlinkEcom";

import AddAccounts from "pages/AddAccounts";

import "react-loading-skeleton/dist/skeleton.css";

function App() {
  return (
    <>
      <Online>
        <Router>
          <ScrollToTop />
          <Routes>
            <Route path="/" element={<Layout />}>
              <Route path="/" element={<Home />} />
              <Route path="/work" element={<Works />} />
              <Route path="/work/:slug" element={<WorkPage />} />
              <Route path="/jobs" element={<Jobs />} />
              <Route path="/initiatives" element={<Initiatives />} />
              {/* <Route path="/news" element={<News />} /> */}
              <Route path="/culture" element={<Culture />} />
              <Route path="/thinking" element={<Thinking />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/people" element={<People />} />

              <Route path="*" element={<NotFound />} />
            </Route>
            <Route path="/doojramchandaniimdb" element={<Dooj />} />
            <Route path="/build-ecomm" element={<BlinkEcom />} />
            <Route path="/media-solutions" element={<AddAccounts />} />
          </Routes>
        </Router>
      </Online>
      <Offline>
        <Router>
          <Routes>
            <Route path="*" element={<OfflinePage />} />
          </Routes>
        </Router>
      </Offline>
    </>
  );
}

export default App;
