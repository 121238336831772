import React, { useRef, useState, useEffect } from "react";
import { useGetWorksQuery } from "store/work/workApi";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css"; // Import Swiper styles
import LazyLoad from "./LazyLoad";
import { Link } from "react-router-dom";
import ArrowPng from "../assets/images/ArrowWork.png";
import RightArrow from "../assets/images/righArrow.png";
import WorkSliderSkeleton from "./skeletons/WorkSliderSkeleton";



function WorkSlider({ title, category, filters }) {
  const swiperRef = useRef(null); // Create a reference for the Swiper
  const [viewportWidth, setViewportWidth] = useState(window.innerWidth); // Track viewport width

  const [currentSlide, setCurrentSlide] = useState(1);
  let slidesPerView_ = viewportWidth > 768 ? 2 : 1;
  let slidesToScroll_ = viewportWidth > 768 ? 1 : 1;

  // Update viewport width on window resize
  useEffect(() => {
    const handleResize = () => setViewportWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleNext = () => {
    if (swiperRef.current) {
      swiperRef.current.swiper.slideNext(); // Move to the next slide
    }
  };

  const handlePrev = () => {
    if (swiperRef.current) {
      swiperRef.current.swiper.slidePrev(); // Move to the previous slide
    }
  };

  const getFilterQueryString = () => {
    let query = `&sort=rank:asc`;

    filters.forEach((filter) => {
      query += `&filters[${category}][title][$in]=${encodeURIComponent(
        filter
      )}`;
    });

    return query;
  };

  const {
    data: works,
    isLoading,
    refetch,
    isFetching,
  } = useGetWorksQuery({
    query: getFilterQueryString(),
  });

  //console.log(works?.data);

  const slides = works?.data?.map((slide, index) => {
    const titleCopy = slide?.attributes?.title;
    const titleWithBreaks = titleCopy.replace(/\n/g, "<br />");

    return (
      <SwiperSlide key={`swipeSlide${index}`}>
        <Link to={`/work/${slide?.attributes?.slug}`} className="card-item">
          <div
            style={{
              backgroundColor: "rgba(255, 255, 255, 0.568627451)",
            }}
          >
            <div className="work-card-wrapper">
              <h2 className="name category">
                {slide?.attributes?.brand?.data?.attributes?.title}
              </h2>
              <div className="hover-image-container">
                <LazyLoad
                  className="hover-img"
                  src={
                    slide?.attributes?.thumbnail?.data?.attributes?.formats
                      ?.small?.url
                  }
                  dataSrc={slide?.attributes?.thumbnail?.data?.attributes?.url}
                />
              </div>
            </div>
            <div className="copy-wrap">
              <div className="rolling-copy-wrap">
                <div className="name-wrap">
                  {slide?.attributes?.title?.split("\n").map((line, index) => (
                    <p className="name" key={index}>
                      {line}
                      <br />
                    </p>
                  ))}
                </div>
              </div>
              <div className="img-wrapper hidden-xs">
                <img src={ArrowPng} alt="thumbnail" />
              </div>
              <div className="img-wrapper-1 hidden-lg">
                <img src={ArrowPng} alt="thumbnail" />
              </div>
            </div>
          </div>
        </Link>
      </SwiperSlide>
    );
  });

  const shouldDisableArrows =
    (viewportWidth > 768 && works?.data?.length < 3) ||
    (viewportWidth <= 768 && works?.data?.length < 1);

  return (
    <>
      {isLoading ? (
        <WorkSliderSkeleton />
      ) : (
        <>
          {works?.data?.length > 0 ? (
            <div>
              <h4 className="Slider-heading">{title}</h4>
              <div className="slider-wrapper" style={{ position: "relative" }}>
                {!shouldDisableArrows && (
                  <>
                    <div className="total-slide">
                      {viewportWidth > 768 ? currentSlide + 1 : currentSlide} /
                      {slides?.length}
                    </div>
                    <>
                      <button
                        className={
                          currentSlide == 1 ? "disabled left-arrow" : "left-arrow"
                        }
                        onClick={handlePrev}
                      >
                        <img src={RightArrow} alt="arrow" />
                      </button>
                      <button
                        className={
                          currentSlide == slides?.length
                            ? "disabled right-arrow"
                            : "right-arrow"
                        }
                        onClick={handleNext}
                      >
                        <img src={RightArrow} alt="arrow" />
                      </button>
                    </>
                  </>
                )}
                <Swiper
                  ref={swiperRef}
                  onSlideChange={(swiper) =>
                    setCurrentSlide(swiper.activeIndex + 1)
                  }
                  spaceBetween={40}
                  slidesPerView={slidesPerView_}
                  slidesPerGroup={slidesToScroll_}
                  breakpoints={{
                    768: {
                      slidesPerView: slidesPerView_,
                      slidesPerGroup: slidesToScroll_,
                      spaceBetween: 80,
                    },
                  }}
                >
                  {slides}
                </Swiper>
              </div>
            </div>
          ) : null}
        </>
      )}


    </>
  );
}

export default WorkSlider;
