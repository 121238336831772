import React from 'react'
import Skeleton from 'react-loading-skeleton';


function WorkSliderSkeleton() {
    return (
        <div style={{ width: "100%", marginTop: 100 }}>
            <div style={{ display: "flex", justifyContent: "flex-start" }} className='skeleton-wrap'>
                <Skeleton width={150} height={30} />
            </div>

            <div
                style={{
                    display: "flex",
                    gap: "80px",
                    marginTop: "25px",
                    flexWrap: "wrap",
                }}
            >
                <div style={{ flex: "1", minWidth: "300px" }} className='skeleton-wrap'>
                    <Skeleton height={300} width="100%" />
                </div>
                <div style={{ flex: "1", minWidth: "300px" }} className='skeleton-wrap'>
                    <Skeleton height={300} width="100%" />
                </div>
            </div>
        </div>
    )
}

export default WorkSliderSkeleton