import React from "react";

const ScrollToTopButton = ({ isVisible, onClick }) => {
  return (
    isVisible && (
      <button onClick={onClick} style={styles.button}>
        ↑
      </button>
    )
  );
};

const styles = {
  button: {
    position: "fixed",
    bottom: "20px",
    right: "20px",
    padding: "10px 15px",
    fontSize: "20px",
    backgroundColor: "#0C9",
    color: "#fff",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
  },
};

export default ScrollToTopButton;
