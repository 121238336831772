import { useEffect, useState } from "react";
import ReactPlayer from "react-player/vimeo";
import { Link, useParams } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { useGetRelatedWorksQuery, useGetWorkQuery } from "store/work/workApi";
import BrandEquity from "../assets/images/BE-Influencer-marketing.png";
import ETBrand from "../assets/images/Brand-disruption.png";
import DG from "../assets/images/DG.png";
import Golden from "../assets/images/Golden.png";
import Sammie from "../assets/images/Sammie.png";
import Digies from "../assets/images/Digies.png";
import Echo from "../assets/images/echo copy.png";
import IndiaDigital from "../assets/images/IAMAI.png";
import { default as DMA, default as Indies } from "../assets/images/Indies.png";
import Mom from "../assets/images/MOM.png";
import ArrowPng from "../assets/images/Arrow1.png";

import Pointer from "../assets/images/Playbutton.png";

import {
  default as oneShow,
  default as Sparkies,
} from "../assets/images/one-show.png";
import TheWork from "../assets/images/the-work.png";
import Trendies from "../assets/images/Trendies.png";
import { default as webby, default as Webby } from "../assets/images/webby.png";
import YTWorks from "../assets/images/YT-works.png";

import DynamicLightbox from "components/DynamicLightbox";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlayCircle } from "@fortawesome/free-solid-svg-icons";
import Clio from "../assets/images/clio.png";
import Kyoorius from "../assets/images/elephant.png";
import DynamicVideobox from "components/DynamicVideobox";
import Skeleton from "react-loading-skeleton";
import WorkPageSkeleton from "components/skeletons/WorkPageSkeleton";

const WorkPage = () => {
  let { slug } = useParams();
  const [videos, setVideos] = useState([]);
  const [playing, setPlaying] = useState(false);
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);

  // Function to move to the next video or loop back to the start
  const handleVideoEnd = () => {
    if (currentVideoIndex < videos.length - 1) {
      setCurrentVideoIndex((prevIndex) => prevIndex + 1);
    } else {
      setPlaying(false); // Stop playing on the last video
    }
  };

  const { data: work, error, isLoading } = useGetWorkQuery({ slug });
  const { data: relatedWorks, isRelatedrWorksLoading } =
    useGetRelatedWorksQuery({ slug });


  useEffect(() => {
    if (work?.attributes) {
      let moreVideos = work?.attributes?.more_links?.split(",") || [];
      setVideos([work?.attributes?.vimeo_link, ...moreVideos]);
    }
  }, [work]);

  const servicesList = work?.attributes?.services?.data?.map(
    (service, index) => (
      <p className="work-title" key={`service_${index}`}>
        {service?.attributes?.title}
      </p>
    )
  );

  function parseAwardsString(str) {
    const brandParts = str?.split(", ");

    const resultArray = [];

    brandParts?.forEach((part) => {
      let [brand, awardsString] = part?.split(" - ");

      const awardsParts = awardsString?.split(" • ");

      const awards = {};

      awardsParts?.forEach((award) => {
        const match = award?.match(/(\d+) x (.+)/);
        if (match) {
          const count = parseInt(match[1], 10);
          const category = match[2];

          if (awards[category]) {
            awards[category] += count;
          } else {
            awards[category] = count;
          }
        }
      });

      brand = brand?.trim();

      resultArray.push({ brand, awards });
    });

    return resultArray;
  }

  let awardsArray;
  let awardsHTML;

  if (work?.attributes?.awards?.length > 0) {
    awardsArray = parseAwardsString(work?.attributes?.awards);
    // console.log(awardsArray);

    awardsHTML = awardsArray?.map((item, index) => {
      //console.log(item);
      let awardImage = webby;
      if (item?.brand == "Abby One Show" || item?.brand == "One Show") {
        awardImage = oneShow;
      } else if (item?.brand == "The Mommys") {
        awardImage = Mom;
      } else if (item?.brand == "DMA Asia ECHO") {
        awardImage = Echo;
      } else if (item?.brand == "India Digital Awards") {
        awardImage = IndiaDigital;
      } else if (item?.brand == "ET Influencer Marketing Awards") {
        awardImage = BrandEquity;
      } else if (item?.brand == "Golden Award of Montreux") {
        awardImage = Golden;
      } else if (item?.brand == "Sparkies") {
        awardImage = Sparkies;
      } else if (item?.brand == "ET Brand Disruption") {
        awardImage = ETBrand;
      } else if (item?.brand == "Webby") {
        awardImage = Webby;
      } else if (item?.brand == "DG+") {
        awardImage = DG;
      } else if (item?.brand == "The Work") {
        awardImage = TheWork;
      } else if (item?.brand == "YouTube Works") {
        awardImage = YTWorks;
      } else if (item?.brand == "Brand Equity Trendies") {
        awardImage = Trendies;
      } else if (item?.brand == "WPI Grand Indies") {
        awardImage = Indies;
      } else if (item?.brand == "Digies") {
        awardImage = Digies;
      } else if (item?.brand == "Sammies") {
        awardImage = Sammie;
      } else if (item?.brand == "Clio") {
        awardImage = Clio;
      } else if (item?.brand == "Kyoorius") {
        awardImage = Kyoorius;
      }

      const awardMeta = Object.entries(item?.awards).map(
        ([key, value], index, row) => (
          <div key={`awardMeta_${key}`}>
            <p className="copy-para">{`${value} x ${key}`}</p>
            {index + 1 != row.length ? <span>•</span> : null}
          </div>
        )
      );

      return (
        <div className="copy-item" key={`awardItem_wrap_${index}`}>
          <h4 className="copy-head">
            <img src={awardImage} key={`awardImage_${index}`} />
          </h4>
          <div className="copy-meta">{awardMeta}</div>
        </div>
      );
    });
  }

  var settings = {
    // dots: true,
    autoplay: true,
    autoplaySpeed: 2000,
    infinite: work?.attributes?.gallery?.data?.length > 1,
    arrows: false,

    speed: 1000,
    slidesToShow: 2,
    slidesToScroll: 1,
    centerPadding: "300px",
    centerMode: false,
    autoplay: true,
    speed: 10000,
    autoplaySpeed: 0,
    cssEase: "linear",
    pauseOnHover: true,
    pauseOnFocus: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          centerPadding: "10px",
        },
      },
    ],
  };

  let stillSectionTitle = '';
  if (work?.attributes?.services?.data?.[0]?.attributes?.title == 'Film & Video') {
    stillSectionTitle = 'Film Stills'
  } else if (work?.attributes?.services?.data?.[0]?.attributes?.title == 'Social & Creators') {
    stillSectionTitle = 'Social Posts'
  } else if (work?.attributes?.services?.data?.[0]?.attributes?.title == 'Website') {
    stillSectionTitle = 'Website Stills'
  } else if (work?.attributes?.services?.data?.[0]?.attributes?.title == 'Apps & Software') {
    stillSectionTitle = 'App Stills'
  } else {
    stillSectionTitle = 'Campaign Stills'
  }

  const relatedWorksList = relatedWorks?.map((work, index) => {
    return (
      <a
        href={`/work/${work?.attributes?.slug}`}
        className="flex-item"
        key={`similar-works_${index}`}
      >
        <div className="hover-image-container">
          <img
            className="main-image"
            src={work?.attributes?.thumbnail?.data?.attributes?.url}
          />
        </div>
        <div className="img-title">
          <p className="name">
            {work?.attributes?.brand?.data?.attributes?.title}
          </p>
          <div className="rolling-copy-wrap">
            <h4 className="discr">{work?.attributes?.title}</h4>
            <img className="arrow-png hidden-xs" src={ArrowPng} />
            <img className="arrow-png-1 hidden-lg" src={ArrowPng} />
          </div>
          {/* <p className="view-more">View case study</p> */}
        </div>
      </a>
    );
  });

  const stillGallery = work?.attributes?.gallery?.data?.map(
    (slideItem, index) => {
      return (
        <div className="img-flex-item" key={`still-gallery_${index}`}>
          <DynamicLightbox image={slideItem?.attributes?.url} />
        </div>
      );
    }
  );

  return (
    <div className="wrapper-player" data-scroll-section>
      <div className="player container">
        {isLoading ? (
          <WorkPageSkeleton />
        ) : (
          <div className="player-copy-wrapper">
            <div className="page-copy">
              <p className="top-bottom-copy">
                {work?.attributes?.brand?.data?.attributes?.title}
              </p>
              <h3 className=" top-left-copy">{work?.attributes?.title}</h3>
            </div>
            <div>
              <DynamicVideobox
                videoUrl={videos[currentVideoIndex]}
                imageUrl={work?.attributes?.cover?.data?.attributes?.url}
                onEnded={handleVideoEnd}
                playing={playing}
                onPlayClick={() => {
                  setCurrentVideoIndex(0);
                  setPlaying(true);
                }}
              />
            </div>

            {work?.attributes?.more_links?.length > 0 ? (
              <>
                <p className="copy-thumbnail">More from the same campaign</p>
                <div className="video-thumbnails">
                  {videos?.map((id, index) => (
                    <a
                      className={
                        videos[currentVideoIndex] == id
                          ? "thumbnail-item active"
                          : "thumbnail-item"
                      }
                      key={index}
                      onClick={() => {
                        setCurrentVideoIndex(index);
                        setPlaying(true);
                      }}
                    >
                      <img
                        src={`https://vumbnail.com/${id}.jpg`}
                        alt={`Video ${index + 1}`}
                        width="300px"
                        height="56.25px"
                      />
                      {videos[currentVideoIndex] != id ? (
                        <FontAwesomeIcon
                          icon={faPlayCircle}
                          color="white"
                          className="vimeo-play-icon"
                        />
                      ) : null}
                    </a>
                  ))}
                </div>
              </>
            ) : null}
          </div>
        )}
      </div>
      <div className="video-details">
        <div className="video-detail-wrapper container">
          <div className="detail-item">
            <div className="item-1">
              <h4 className="client">Industry</h4>
              <p className="work-title">
                {work?.attributes?.industry?.data?.attributes?.title}
              </p>
            </div>
          </div>
          {work?.attributes?.services?.data?.length ? (
            <div className="detail-item">
              <div className="item-1">
                <h4 className="client">Services</h4>
                {servicesList}
              </div>
            </div>
          ) : null}

          {work?.attributes?.overview ? (
            <div className="detail-item detail-item-2">
              <div className="item-1 ">
                <h4 className="client">Overview</h4>
                <p className="work-title">{work?.attributes?.overview}</p>
              </div>
            </div>
          ) : null}
        </div>
      </div>
      {work?.attributes?.gallery?.data?.length > 0 ? (
        <div className="shoot-image">
          <div className="shoot-wrapper ">
            <h3 className="head-copy container">{stillSectionTitle}</h3>
            <div className="image-wrapper">
              <Slider {...settings}>{stillGallery}</Slider>
            </div>
          </div>
        </div>
      ) : null}

      {work?.attributes?.idea || work?.attributes?.challenge ? (
        <div className="all-details">
          <div className="all-details-wrapper container">
            <div className="wrapper-flex">
              {work?.attributes?.challenge ? (
                <div className="flex-item-wrapper">
                  <div className="title">
                    <h4>The Challenge</h4>
                  </div>
                  <div className="copy copy-text">
                    {work?.attributes?.challenge}
                  </div>
                </div>
              ) : null}

              {work?.attributes?.idea ? (
                <div className="flex-item-wrapper">
                  <div className="title">
                    <h4>The Idea</h4>
                  </div>
                  <div className="copy copy-text">{work?.attributes?.idea}</div>
                </div>
              ) : null}

              {work?.attributes?.result_1 ? (
                <div className="flex-item-wrapper">
                  <div className="title">
                    <h4>The Results</h4>
                  </div>
                  <div className="copy result-mobile">
                    <div className="copy-item">
                      <h4 className="copy-head">
                        {work?.attributes?.result_1?.split("-")[0]}
                      </h4>
                      <p className="copy-para">
                        {work?.attributes?.result_1?.split("-")[1]}
                      </p>
                    </div>
                    {work?.attributes?.result_2 ? (
                      <div className="copy-item">
                        <h4 className="copy-head">
                          {work?.attributes?.result_2?.split("-")[0]}
                        </h4>
                        <p className="copy-para">
                          {work?.attributes?.result_2?.split("-")[1]}
                        </p>
                      </div>
                    ) : null}
                    {work?.attributes?.result_3 ? (
                      <div className="copy-item">
                        <h4 className="copy-head">
                          {work?.attributes?.result_3?.split("-")[0]}
                        </h4>
                        <p className="copy-para">
                          {work?.attributes?.result_3?.split("-")[1]}
                        </p>
                      </div>
                    ) : null}
                  </div>
                </div>
              ) : null}

              {work?.attributes?.awards?.length > 0 ? (
                <div className="flex-item-wrapper ">
                  <div className="title">
                    <h4>Awards</h4>
                  </div>
                  <div className="copy award-mobile">{awardsHTML}</div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      ) : null}

      <div className="related-work">
        <div className="related-work-wrapper container">
          <h3 className="head-copy">Related Work</h3>
          <div className="wrapper-flex">{relatedWorksList}</div>
        </div>
      </div>
    </div>
  );
};

export default WorkPage;
