import React from "react";
import Dooj from "../assets/images/Dooj-new.jpg";
import Khushboo from "../assets/images/Khushboo.jpg";
import Nikole from "../assets/images/Nicole.jpg";
import Renne from "../assets/images/renne.jpg";
import Rikki from "../assets/images/Rikki.jpg";
import Michell from "../assets/images/Michell-new.jpg";
import Prerna from "../assets/images/Prerna.jpg";
import Dia from "../assets/images/Dia.jpg";
import Jana from "../assets/images/Jana_1.jpg";
import Yogesh from "../assets/images/Yogesh.jpg";
import ArrowPng from "../assets/images/ArrowWork.png";
import { Link } from "react-router-dom";

function People() {
  return (
    <div id="people">
      <div className="people-wrapper container">
        <div className="people-head-wrap">
          <h2 className="contact-heading">People</h2>
          <p>
            Ideas shape the world,
            <br /> our people shape ideas
          </p>
        </div>
        <div className="people-card-wrapper">
          {/* dooj */}
          <Link
            target="_blank"
            to="https://in.linkedin.com/in/doojramchandani"
            className="people-card"
          >
            <div className="people-info">
              <p className="designation">Co-Founder & CEO</p>
              <h2 className="name">
                Dooj <br /> Ramchandani{" "}
                <span className="arrow-png">
                  <img src={ArrowPng} alt="arrow" />
                </span>{" "}
              </h2>
            </div>
            <div className="img-wrap">
              <img src={Dooj} alt="name-people" />{" "}
            </div>
          </Link>

          {/* rikki */}
          <Link
            target="_blank"
            to="https://in.linkedin.com/in/rikki-agarwal-79ab6926"
            className="people-card reverse-mobile"
          >
            <div className="people-info">
              <p className="designation">Co-Founder & COO</p>
              <h2 className="name">
                Rikki <br /> Agarwal{" "}
                <span className="arrow-png">
                  <img src={ArrowPng} alt="arrow" />
                </span>{" "}
              </h2>
            </div>
            <div className="img-wrap">
              <img src={Rikki} alt="name-people" />
            </div>
          </Link>

          {/* dia */}
          <Link
            to="https://in.linkedin.com/in/dia-kirpalani-84168612"
            target="_blank"
            className="people-card reverse"
          >
            <div className="people-info">
              <p className="designation">VP, Head of Strategy</p>

              <h2 className="name">
                Dia <br /> Kirpalani{" "}
                <span className="arrow-png">
                  <img src={ArrowPng} alt="arrow" />
                </span>{" "}
              </h2>
            </div>
            <div className="img-wrap">
              <img src={Dia} alt="name-people" />
            </div>
          </Link>

          {/* khushboo */}
          <Link
            to="https://in.linkedin.com/in/khushboo-bhatt-0b80785a"
            target="_blank"
            className="people-card reverse reverse-mobile"
          >
            <div className="people-info">
              <p className="designation">AVP - Creative Growth</p>
              <h2 className="name">
                Khushboo <br /> Bhatt{" "}
                <span className="arrow-png">
                  <img src={ArrowPng} alt="arrow" />
                </span>{" "}
              </h2>
            </div>
            <div className="img-wrap">
              <img src={Khushboo} alt="name-people" />
            </div>
          </Link>

          {/* Amer */}
          {/* <div className="people-card">
            <div className="people-info">
              <p className="designation"> Director - Technology</p>
              <h2 className="name">
                {" "}
                Amer <br /> Ahmad{" "}
                <span className="arrow-png">
                  <img src={ArrowPng} alt="arrow" />
                </span>{" "}
              </h2>
            </div>
            <div className="img-wrap">
              <img src={Dooj} alt="name-people" />
            </div>
          </div> */}

          {/* yogesh */}

          <Link
            to="https://in.linkedin.com/in/yogesh-shirke-85317a227"
            target="_blank"
            className="people-card"
          >
            <div className="people-info">
              <p className="designation"> Creative Director </p>
              <h2 className="name">
                Yogesh <br /> Shirke{" "}
                <span className="arrow-png">
                  <img src={ArrowPng} alt="arrow" />
                </span>{" "}
              </h2>
            </div>
            <div className="img-wrap">
              <img src={Yogesh} alt="name-people" />
            </div>
          </Link>
          <Link
            to="https://in.linkedin.com/in/michell-anthony-416b1a28"
            target="_blank"
            className="people-card "
          >
            <div className="people-info">
              <p className="designation">Director - Human Resources</p>
              <h2 className="name">
                Michell <br /> Anthony{" "}
                <span className="arrow-png">
                  <img src={ArrowPng} alt="arrow" />
                </span>{" "}
              </h2>
            </div>
            <div className="img-wrap">
              <img src={Michell} alt="name-people" />
            </div>
          </Link>

          {/* yogesh */}
          {/* <div className="people-card">
            <div className="people-info">
              <p className="designation"> Creative Director </p>
              <h2 className="name">
                Jana <br /> Colaco{" "}
                <span className="arrow-png">
                  <img src={ArrowPng} alt="arrow" />
                </span>{" "}
              </h2>
            </div>
            <div className="img-wrap">
              <img src={Jana} alt="name-people" />
            </div>
          </div> */}

          {/* prerna */}
          <Link
            to="https://in.linkedin.com/in/prerana-parab-2b5b2666"
            target="_balnk"
            className="people-card reverse"
          >
            <div className="people-info">
              <p className="designation">Associate Creative Director </p>
              <h2 className="name">
                Prerana <br /> Parab{" "}
                <span className="arrow-png">
                  <img src={ArrowPng} alt="arrow" />
                </span>{" "}
              </h2>
            </div>
            <div className="img-wrap">
              <img src={Prerna} alt="name-people" />
            </div>
          </Link>

          {/* michell */}
        </div>
      </div>
    </div>
  );
}

export default People;
