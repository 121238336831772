import { api } from '../api';

export const contactApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getContacts: builder.query({
            query: () => ({
                url: `/contact/?populate=*`,
                method: 'GET',
            }),
            transformResponse: (response, meta, arg) => response.data,
        }),
        getOffices: builder.query({
            query: () => ({
                url: `/offices/?sort=id:asc&populate=*`,
                method: 'GET',
            }),
            transformResponse: (response, meta, arg) => response.data,
        }),
        getTimeZones: builder.query({
            query: () => ({
                url: `/timezones/?populate=*`,
                method: 'GET',
            }),
            transformResponse: (response, meta, arg) => response.data,
        }),
        getDoojData: builder.query({
            query: () => ({
                url: `/dooj/?populate=*`,
                method: 'GET',
            }),
            transformResponse: (response, meta, arg) => response.data,
        }),

    })
})

export const { useGetContactsQuery, useGetOfficesQuery, useGetTimeZonesQuery, useGetDoojDataQuery } = contactApi;