import React, { useState, useEffect } from "react";
import { SnapchatPixel, snaptr } from "react-use-snapchat-pixel";
const GoogleFormEmbed = () => {
  const [snapchatPixel, setSnapchatPixel] = useState(null);
  const [isPixelReady, setIsPixelReady] = useState(false);

  useEffect(() => {
    const initializeSnapchatPixel = () => {
      try {
        const pixel = new SnapchatPixel({
          pixelID: "42c8d530-53ce-4602-8e78-e0195d4f5d98",
        });

        // Remove await, since init() might not be async
        setSnapchatPixel(pixel);
        setIsPixelReady(true);
        isPixelReady && pixel.init();
        console.log("Snapchat Pixel initialized");
      } catch (error) {
        console.error("Snapchat Pixel initialization failed:", error);
      }
    };

    initializeSnapchatPixel();
  }, []);

  const [formData, setFormData] = useState({
    company_name: "",
    contact: "",
    business_email: "",
    based_from: "",
    spend: "",
    platform: "", // Single selection
    landing_page: "",
  });

  const placeholders = {
    company_name: "Entity name*",
    contact: "Contact number*",
    business_email: "Email*",
    based_from: "City*",
    spend: "Spend*",
    landing_page: "Landing page URL*",
  };

  const [errors, setErrors] = useState({});
  const [showModal, setShowModal] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const validateForm = () => {
    let newErrors = {};
    if (!formData.company_name)
      newErrors.company_name = "Company name is required.";
    if (!formData.contact.match(/^[0-9]{10}$/))
      newErrors.contact = "Enter a valid 10-digit number.";
    if (!formData.business_email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/))
      newErrors.business_email = "Enter a valid email.";
    if (!formData.based_from) newErrors.based_from = "Location is required.";
    if (!formData.spend) newErrors.spend = "Estimated spend is required.";
    if (!formData.platform) newErrors.platform = "Please select a platform.";
    if (!formData.landing_page.match(/^https?:\/\//))
      newErrors.landing_page = "Enter a valid URL.";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  async function hashSHA256(value) {
    const encoder = new TextEncoder();
    const data = encoder.encode(value.trim().toLowerCase()); // Normalize input
    const hashBuffer = await crypto.subtle.digest("SHA-256", data);
    return Array.from(new Uint8Array(hashBuffer))
      .map((byte) => byte.toString(16).padStart(2, "0"))
      .join("");
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    const hashedEmail = await hashSHA256(formData.business_email);
    const hashedPhone = await hashSHA256(formData.contact);

    fetch("https://business-api.tiktok.com/open_api/v1.3/event/track/", {
      method: "POST",
      headers: {
        "Access-Token": "d912fada7cf555f25b1b3395cf0e1e76f7b600e3",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        event_source: "web",
        event_source_id: "CUM6JSJC77UC80LSNIVG",
        data: [
          {
            event: "SubmitForm",
            event_time: 1740630261,
            user: {
              email: hashedEmail,
              phone: hashedPhone,
              external_id: null,
            },
            properties: {
              currency: null,
              content_type: null,
            },
            page: {
              url: null,
              referrer: null,
            },
          },
        ],
      }),
    })
      .then((response) => response.json())
      .then((data) => console.log(data))
      .catch((error) => console.error("Error:", error));

    try {
      const response = await fetch(
        "https://hook.eu2.make.com/8itdgnut31n7b7e5z2wvviix557isgoy",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(formData),
        }
      );

      if (response.ok) {
        if (isPixelReady && snapchatPixel) {
          snapchatPixel.trackEvent("CUSTOM_EVENT_1", {
            uuid_c1: "unique-session-id-12345", // Replace with actual session ID
            user_email: formData.business_email, // Replace with actual user email
            user_phone_number: formData.contact, // Replace with actual phone number
            // user_hashed_email: "e99a18c428cb38d5f260853678922e03", // Example hashed email (SHA-256)
            // user_hashed_phone_number: "c88d60ec2e5f7c6bbec30b23b30b2c7f", // Example hashed phone number (SHA-256)
          });
        }

        setShowModal(true);
        setFormData({
          company_name: "",
          contact: "",
          business_email: "",
          based_from: "",
          spend: "",
          platform: "",
          landing_page: "",
        });
        setErrors({});
      } else {
        alert("Error submitting form.");
      }
    } catch (error) {
      alert("Network error. Please try again.");
    }
  };

  return (
    <div className="form-wrapper-get-in-touch">
      <div className="wrapper-get-in-touch ">
        <h2>Contact Form</h2>
        {!showModal ? (
          <form onSubmit={handleSubmit}>
            <div className="form-grid">
              {Object.entries(formData).map(([key, value]) =>
                key !== "platform" ? (
                  <div key={key}>
                    <input
                      type={key === "spend" ? "number" : "text"}
                      name={key}
                      value={value}
                      onChange={handleChange}
                      placeholder={
                        placeholders[key] || `${key.replace("_", " ")}*`
                      }
                    />
                    {errors[key] && (
                      <p className="error-form" style={{ color: "white" }}>
                        {errors[key]}
                      </p>
                    )}
                  </div>
                ) : null
              )}
            </div>

            {/* Select Dropdown for Platform */}
            <div className="input-wrapper">
              {/* <label>Platform Needed*</label> */}
              <select
                name="platform"
                value={formData.platform}
                onChange={handleChange}
              >
                <option value="">Platform*</option>
                <option value="Meta">Meta</option>
                <option value="Google">Google</option>
                <option value="TikTok">TikTok</option>
                <option value="Bing">Bing</option>
                <option value="Other">Other</option>
              </select>
              {errors.platform && (
                <p className="error-form" style={{ color: "white" }}>
                  {errors.platform}
                </p>
              )}
            </div>

            <button type="submit">Submit</button>
          </form>
        ) : (
          <div className="back-plate">
            <h4>Success</h4>
            <p className="success-p">
              Your form has been submitted successfully!
            </p>
            <button onClick={() => setShowModal(false)}>OK</button>
          </div>
        )}
      </div>
    </div>
  );
};

export default GoogleFormEmbed;
