import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
// import Slider from "react-slick";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css";

import AnimatedAboutUs from "./AnimatedAboutUs";
import TeamSlider from "components/TeamSlider";
import AgodaThumb from "../assets/images/Agoda.png";
// import Award from "../assets/images/award.png";
import Clio from "../assets/images/clio.png";
import Echo from "../assets/images/echo copy.png";
import EchoThumb from "../assets/images/echo-thumb.png";
import Elephant from "../assets/images/elephant.png";
import OneShow from "../assets/images/one-show.png";
import WattaBoxThumb from "../assets/images/Watt-A-Box.png";
import Webby from "../assets/images/webby.png";

import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { SplitText } from "gsap/SplitText";

import NumberCountTicker from "components/NumberCountTicker";
import { useEffect, useRef, useState } from "react";

gsap.registerPlugin(ScrollTrigger, SplitText, ScrollToPlugin);

function Home() {
  const [isMobileView, setisMobileView] = useState(false);
  const headingsRef = useRef(null);
  const section2Ref = useRef(null);

  let mm = gsap.matchMedia();

  useEffect(() => {
    mm.add("(max-width: 800px)", () => {
      setisMobileView(true);
    });
  }, []);

  useEffect(() => {
    const headings = headingsRef.current;
    const h1 = headings.querySelectorAll("h1");
    const topCopy = headings.querySelectorAll(".top-copy");
    const bottomCopy = headings.querySelectorAll(".bottom-copy");

    var splitH1 = new SplitText(h1, { type: "chars" });
    var splitTopCopy = new SplitText(topCopy, { type: "chars" });
    var splitBottomCopy = new SplitText(bottomCopy, { type: "chars" });
    //now animate each character into place from 100px above, fading in:
    // gsap.from(splitH1.chars, {
    //   duration: 1,
    //   yPercent: 130,
    //   autoAlpha: 0,
    //   stagger: 0.05,
    //   ease: "back.inOut",
    // });

    // gsap.from(splitTopCopy.chars, {
    //   duration: 0.8,
    //   yPercent: 100,
    //   autoAlpha: 0,
    //   // ease: "back.inOut",
    // });

    // gsap.from(splitBottomCopy.chars, {
    //   duration: 1.8,
    //   y: 100,
    //   autoAlpha: 0,
    // });
  }, []);

  const tooltipStyles = {
    backgroundColor: "black",
    color: "#fff",
    padding: "10px",
    borderRadius: "5px",
    fontSize: "14px",
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.5)",
    fontFamily: "Neue Haas Grotesk Display Pro Roman",
  };

  const tooltipArrowStyles = {
    color: "#333", // Matches the background color of the tooltip
  };

  const handleScrollToSection = () => {
    gsap.to(window, { duration: 1, scrollTo: section2Ref.current });
  };

  const settings = {
    dots: false,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    centerMode: false,
    centerPadding: "10px",
    rtl: false,
    slidesToScroll: 1,
    // cssEase: "linear", // Ensure continuous smooth scrolling
    swipeToSlide: true,
    touchThreshold: 15, // Reduce touch threshold for smoother swipes
    cssEase: "linear",

    responsive: [
      {
        breakpoint: 768, // Mobile breakpoint (adjust as needed)
        settings: {
          slidesToShow: 1.35, // Show 1 slide on mobile
          slidesToScroll: 1,
          centerMode: true,
          centerPadding: "0",
          // Disable center mode on mobile
        },
      },
    ],

    // nextArrow: <SampleNextArrow />,
    // prevArrow: <SamplePrevArrow />,
  };

  return (
    <div data-scroll-section>
      <section
        id="hero"
        //  onMouseMove={(e) => dynamicCursor(e)}
      >
        <div className="headings container" ref={headingsRef}>
          <div className="top-copy-container">
            <p className="top">Hello, we are</p>
          </div>
          <div className="head-copy-container">
            {/* <h1 className="">Blink Digital</h1> */}
            <h1>
              <span>B</span>
              <span>l</span>
              <span>i</span>
              <span>n</span>
              <span>k</span>
              <span>&nbsp;</span>
              <span>D</span>
              <span>i</span>
              <span>g</span>
              <span>i</span>
              <span>t</span>
              <span>a</span>
              <span>l</span>
            </h1>
          </div>
          <div className="bottom-copy-container">
            <p className="bottom-copy">
              digital natives and early adopters of the internet
            </p>
          </div>
        </div>
        <a className="" onClick={() => handleScrollToSection()}>
          <div className="scroll-down-svg2 scroll-down-svg "></div>
        </a>
      </section>

      <section id="about" className="container" ref={section2Ref}>
        <div className="about-wrapper">
          <h3 className="center-copy hidden-lg hidden-md">
            fulfil your ambitions <br /> in a consumer
            <br />
            controlled world{" "}
          </h3>

          <div className="top-img-wrap">
            <div
              className="about-image about-image-top"
              data-speed={isMobileView ? 0 : 0.95}
            >
              <div className="img-wrap">
                <div className="black-overlay"></div>

                <img src={AgodaThumb} />
                <p className="copy-below"> raise awareness</p>
              </div>
            </div>
          </div>

          <div
            className="about-image about-image-center"
            data-speed={isMobileView ? 0 : 0.99}
          >
            <div className="img-wrap">
              <div className="black-overlay"></div>
              <img src={WattaBoxThumb} />
              <p className="copy-below"> earn media</p>
            </div>
          </div>
          <div
            className="about-image about-image-bottom"
            data-speed={isMobileView ? 0 : 0.98}
          >
            <div className="img-wrap">
              <div className="black-overlay"></div>

              <img src={EchoThumb} />

              <p className="copy-below"> launch products</p>
            </div>
          </div>
          <h3 className="center-copy hidden-xs hidden-md" data-speed={0.95}>
            fulfil your ambitions <br /> in a consumer
            <br />
            controlled world{" "}
          </h3>
        </div>
      </section>

      <section id="work">
        <div className="work-copy ">
          <AnimatedAboutUs />
        </div>
      </section>

      <section id="team">
        <p className="overlapped-copy-top container">
          driven by <br /> creativity
        </p>
        <div className="team-slider">
          <TeamSlider />
        </div>
        <p className="overlapped-copy-bottom container">
          backed by <br /> technology
        </p>
      </section>

      <section id="awards">
        <h2 className="awards-heading">
          recognised by <br className="hidden-lg" /> the best
        </h2>
        <div className="awards-wrapper container">
          <div
            className="card tooltip"
            data-tooltip-id="tooltip"
            data-tooltip-content="Webby"
          >
            <img src={Webby} alt="" />
            <span className="tooltiptext">Webby</span>
            <p>
              <NumberCountTicker value={3} />x
            </p>
          </div>

          <div
            className="card tooltip"
            data-tooltip-id="tooltip"
            data-tooltip-content="One Show"
          >
            <img src={OneShow} alt="" />
            <span className="tooltiptext">One Show</span>
            <p>
              <NumberCountTicker value={1} />x
            </p>
          </div>

          <div
            className="card tooltip"
            data-tooltip-id="tooltip"
            data-tooltip-content="Clio"
          >
            <img src={Clio} alt="" />
            <span className="tooltiptext">Clio</span>
            <p>
              <NumberCountTicker value={2} />x
            </p>
          </div>

          <div
            className="card tooltip"
            data-tooltip-id="tooltip"
            data-tooltip-content="Blue Elephant"
          >
            <img src={Elephant} alt="" />
            <span className="tooltiptext">Blue Elephant</span>
            <p>
              <NumberCountTicker value={3} />x
            </p>
          </div>

          {/* <div
            className="card tooltip"
            data-tooltip-id="tooltip"
            data-tooltip-content="Effies"
          >
            <img src={Award} alt="" />
            <span className="tooltiptext">Effies</span>
            <p>
              <NumberCountTicker value={2} />x
            </p>
          </div> */}

          <div
            className="card tooltip"
            data-tooltip-id="tooltip"
            data-tooltip-content="Echo"
          >
            <img src={Echo} alt="" />
            <span className="tooltiptext">Echo</span>
            <p>
              <NumberCountTicker value={21} />x
            </p>
          </div>
          {/* 
          <div
            className="card tooltip"
            data-tooltip-id="tooltip"
            data-tooltip-content="BE"
          >
            <img src={BEInfluencer} alt="" />
            <span className="tooltiptext">BE</span>
            <p>
              <NumberCountTicker value={21} />x
            </p>
          </div>

          <div
            className="card tooltip"
            data-tooltip-id="tooltip"
            data-tooltip-content="Brand Discruption"
          >
            <img src={BrandDiscruption} alt="" />
            <span className="tooltiptext">Brand Discruption</span>
            <p>
              <NumberCountTicker value={21} />x
            </p>
          </div>

          <div
            className="card tooltip"
            data-tooltip-id="tooltip"
            data-tooltip-content="DG"
          >
            <img src={DG} alt="" />
            <span className="tooltiptext">DG</span>
            <p>
              <NumberCountTicker value={21} />x
            </p>
          </div>

          <div
            className="card tooltip"
            data-tooltip-id="tooltip"
            data-tooltip-content="Digies"
          >
            <img src={Digies} alt="" />
            <span className="tooltiptext">Digies</span>
            <p>
              <NumberCountTicker value={21} />x
            </p>
          </div>

          <div
            className="card tooltip"
            data-tooltip-id="tooltip"
            data-tooltip-content="Golden"
          >
            <img src={Golden} alt="" />
            <span className="tooltiptext">Golden</span>
            <p>
              <NumberCountTicker value={21} />x
            </p>
          </div>

          <div
            className="card tooltip"
            data-tooltip-id="tooltip"
            data-tooltip-content="Indies"
          >
            <img src={Indies} alt="" />
            <span className="tooltiptext">Indies</span>
            <p>
              <NumberCountTicker value={21} />x
            </p>
          </div>

          <div
            className="card tooltip"
            data-tooltip-id="tooltip"
            data-tooltip-content="Mom"
          >
            <img src={Mom} alt="" />
            <span className="tooltiptext">Mom</span>
            <p>
              <NumberCountTicker value={21} />x
            </p>
          </div>

          <div
            className="card tooltip"
            data-tooltip-id="tooltip"
            data-tooltip-content="The Work"
          >
            <img src={TheWork} alt="" />
            <span className="tooltiptext">The Work</span>
            <p>
              <NumberCountTicker value={21} />x
            </p>
          </div>

          <div
            className="card tooltip"
            data-tooltip-id="tooltip"
            data-tooltip-content="Trendies"
          >
            <img src={Trendies} alt="" />
            <span className="tooltiptext">Trendies</span>
            <p>
              <NumberCountTicker value={21} />x
            </p>
          </div>

          <div
            className="card tooltip"
            data-tooltip-id="tooltip"
            data-tooltip-content="Yt Works"
          >
            <img src={Youtube} alt="" />
            <span className="tooltiptext">Yt Works</span>
            <p>
              <NumberCountTicker value={21} />x
            </p>
          </div> */}
        </div>
        <Tooltip
          id="tooltip"
          style={tooltipStyles}
          arrowStyle={tooltipArrowStyles}
          className="tooltip-react"
        />
      </section>

      <section id="offices">
        <h2 className="offices-heading container">
          slide into our <br className="" />
          DMs today
        </h2>
        <div className="card-wrapper container hidden-xs">
          <div className="card">
            <h2 className="city">Mumbai</h2>
            <div style={{ marginBottom: 25 }}>
              <p className="adj">Our office location </p>
              <h4 className="location">
                102, Firdous Apt, Hill Road, Bandra(W){" "}
              </h4>
            </div>
            <div style={{ marginBottom: 12 }}>
              <p className="adj">Let's talk business </p>
              <h1 className="location">Khushboo Bhatt</h1>
            </div>

            <p className="adj adj1">AVP - Creative Growth</p>
            <a className="adj adj1" href="mailto:khushboo@blinkdigital.in">
              khushboo@blinkdigital.in
            </a>
          </div>

          <div className="card">
            <h2 className="city">Dubai</h2>
            <div style={{ marginBottom: 25 }}>
              <p className="adj">Our office location</p>
              <h4 className="location">302, Building 08 Dubai Media City </h4>
            </div>
            <div style={{ marginBottom: 12 }}>
              <p className="adj">Let's talk business </p>
              <h1 className="location">Amer Ahmad </h1>
            </div>

            <p className="adj adj1">Tech Director</p>
            <a className="adj adj1" href="mailto:amer@blinkdigital.in">
              {" "}
              amer@blinkdigital.in
            </a>
          </div>

          <div className="card">
            <h2 className="city">Amsterdam</h2>
            <div style={{ marginBottom: 25 }}>
              <p className="adj">Our office location </p>
              <h4 className="location">Poortland 66, 1046BD Amsterdam </h4>
            </div>
            <div style={{ marginBottom: 12 }}>
              <p className="adj">Let's talk business </p>
              <h1 className="location">Nicole Ferraz </h1>
            </div>

            <p className="adj adj1">Executive Creative Director</p>
            <a className="adj adj1" href="mailto:nicole@blinkdigital.in">
              nicole@blinkdigital.in
            </a>
          </div>
        </div>

        <div className="adr-slider-mobile ">
          {/* <Slider {...settings}>
            <div className="card">
              <h2 className="city">Mumbai</h2>
              <div style={{ marginBottom: 25 }}>
                <p className="adj">Our office location </p>
                <h4 className="location">
                  102, Firdous Apt, Hill Road, Bandra(W){" "}
                </h4>
              </div>
              <div style={{ marginBottom: 12 }}>
                <p className="adj">Let's talk business </p>
                <h1 className="location">Khushboo Bhatt</h1>
              </div>

              <p className="adj adj1">AVP - Creative Growth</p>
              <a className="adj adj1" href="mailto:khushboo@blinkdigital.in">
                khushboo@blinkdigital.in
              </a>
            </div>

            <div className="card">
              <h2 className="city">Dubai</h2>
              <div style={{ marginBottom: 25 }}>
                <p className="adj">Our office location</p>
                <h4 className="location">302, Building 08 Dubai Media City </h4>
              </div>
              <div style={{ marginBottom: 12 }}>
                <p className="adj">Let's talk business </p>
                <h1 className="location">Amer Ahmad </h1>
              </div>

              <p className="adj adj1">Tech Director</p>
              <a className="adj adj1" href="mailto:amer@blinkdigital.in">
                {" "}
                amer@blinkdigital.in
              </a>
            </div>

            <div className="card">
              <h2 className="city">Amsterdam</h2>
              <div style={{ marginBottom: 25 }}>
                <p className="adj">Our office location </p>
                <h4 className="location">Poortland 66, 1046BD Amsterdam </h4>
              </div>
              <div style={{ marginBottom: 12 }}>
                <p className="adj">Let's talk business </p>
                <h1 className="location">Nicole Ferraz </h1>
              </div>

              <p className="adj adj1">Executive Creative Director</p>
              <a className="adj adj1" href="mailto:nicole@blinkdigital.in">
                nicole@blinkdigital.in
              </a>
            </div>
          </Slider> */}

          <Swiper
            spaceBetween={-15}
            slidesPerView={1.5}
            freeMode={true}
            slidesOffsetBefore={70} // Padding on the left
            slidesOffsetAfter={70}
            loop={false}
            cssMode={true}
            speed={700}
            resistanceRatio={0.3}
            // cssEase="ease-in-out"
            breakpoints={{
              640: {
                // slidesPerView: 2,
              },
              // 768: {
              //   slidesPerView: 3,
              // },
              // 1024: {
              //   slidesPerView: 3,
              // },
            }} // Padding on the right
          >
            <SwiperSlide>
              <div className="card">
                <h2 className="city">Mumbai</h2>
                <div style={{ marginBottom: 25 }}>
                  <p className="adj">Our office location </p>
                  <h4 className="location">
                    102, Firdous Apt, Hill Road, Bandra(W){" "}
                  </h4>
                </div>
                <div style={{ marginBottom: 12 }}>
                  <p className="adj">Let's talk business </p>
                  <h1 className="location">Khushboo Bhatt</h1>
                </div>

                <p className="adj adj1">AVP - Creative Growth</p>
                <a className="adj adj1" href="mailto:khushboo@blinkdigital.in">
                  khushboo@blinkdigital.in
                </a>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="card">
                <h2 className="city">Dubai</h2>
                <div style={{ marginBottom: 25 }}>
                  <p className="adj">Our office location</p>
                  <h4 className="location">
                    302, Building 08 Dubai Media City{" "}
                  </h4>
                </div>
                <div style={{ marginBottom: 12 }}>
                  <p className="adj">Let's talk business </p>
                  <h1 className="location">Amer Ahmad </h1>
                </div>

                <p className="adj adj1">Tech Director</p>
                <a className="adj adj1" href="mailto:amer@blinkdigital.in">
                  {" "}
                  amer@blinkdigital.in
                </a>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="card">
                <h2 className="city">Amsterdam</h2>
                <div style={{ marginBottom: 25 }}>
                  <p className="adj">Our office location </p>
                  <h4 className="location">Poortland 66, 1046BD Amsterdam </h4>
                </div>
                <div style={{ marginBottom: 12 }}>
                  <p className="adj">Let's talk business </p>
                  <h1 className="location">Nicole Ferraz </h1>
                </div>

                <p className="adj adj1">Executive Creative Director</p>
                <a className="adj adj1" href="mailto:nicole@blinkdigital.in">
                  nicole@blinkdigital.in
                </a>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </section>
    </div>
  );
}

export default Home;
