import { useState } from "react";
import WorkSlider from "components/WorkSlider";
import { useGetServicesQuery, useGetIndustriesQuery } from "store/work/workApi";

const Works = () => {
  const [activeTab, setActiveTab] = useState("capability");

  const { data: industries, isLoading: isIndustriesDataLoading } =
    useGetIndustriesQuery();

  const { data: services, isLoading: isServicesDataLoading } =
    useGetServicesQuery();

  // Function to switch tabs
  const handleTabChange = (tabName) => {
    setActiveTab(tabName);
  };

  const serviceSliders = services?.map((service, index) => (
    <WorkSlider
      key={`serviceSlider_${index}`}
      title={service.attributes.title}
      category={"services"}
      filters={[service.attributes.title]}
    />
  ));

  const industrySliders = industries?.map((industry, index) => (
    <WorkSlider
      key={`industrySlider_${index}`}
      title={industry.attributes.title}
      category={"industry"}
      filters={[industry.attributes.title]}
    />
  ));

  return (
    <div className="work-page" data-scroll-section>
      <div className="work-wrapper container">
        <div className="work-banner">
          <h2>Work</h2>
          <p>
            Unlocking the full potential of the internet with <br />{" "}
            breakthrough work tailored for the post digital,
            <br /> consumer controlled world
          </p>
        </div>
      </div>

      {/* Buttons for Tab Navigation */}
      <div className="select-ct container">
        <p className="label-select">Filter by</p>

        <button
          className={`btn-filter ${activeTab === "capability" ? "active" : ""}`}
          onClick={() => handleTabChange("capability")}
        >
          Capability
        </button>
        <button
          className={`btn-filter ${activeTab === "industry" ? "active" : ""}`}
          onClick={() => handleTabChange("industry")}
        >
          Industry
        </button>
      </div>

      {/* Content for Capability Tab */}
      {activeTab === "capability" && (
        <div className="work-ct container">
          {serviceSliders}

          {/* <WorkSlider
            title={"Film & Video"}
            category={"services"}
            filters={["Film & Video"]}
          />
          <WorkSlider
            title={"Activations, Media & PR"}
            category={"services"}
            filters={["Activations, Media & PR"]}
          />
          <WorkSlider
            title={"Websites"}
            category={"services"}
            filters={["Website"]}
          />
          <WorkSlider
            title={"Apps & Software"}
            category={"services"}
            filters={["Apps & Software"]}
          />
          <WorkSlider
            title={"Social & Creators"}
            category={"services"}
            filters={["Social & Creators"]}
          />
          <WorkSlider
            title={"Immersive & Games"}
            category={"services"}
            filters={["Immersive & Games"]}
          />
          <WorkSlider
            title={"Healthcare & Fitness"}
            category={"services"}
            filters={["Healthcare & Fitness"]}
          />
          <WorkSlider
            title={"Other"}
            category={"services"}
            filters={["Other"]}
          /> */}
        </div>
      )}

      {/* Content for Industry Tab */}
      {activeTab === "industry" && (
        <div className="work-ct container">
          {industrySliders}
          {/* 
          <WorkSlider
            title={"Food & Beverage"}
            category={"industry"}
            filters={["Food & Beverage"]}
          />
          <WorkSlider title={"BFSI"} category={"industry"} filters={["BFSI"]} />
          <WorkSlider
            title={"E-commerce"}
            category={"industry"}
            filters={["E-commerce"]}
          />
          <WorkSlider title={"FMCG"} category={"industry"} filters={["FMCG"]} />
          <WorkSlider
            title={"Entertainment"}
            category={"industry"}
            filters={["Entertainment"]}
          />
          <WorkSlider
            title={"E-sports & Gaming"}
            category={"industry"}
            filters={["E-sports & Gaming"]}
          />
          <WorkSlider
            title={"Healthcare & Fitness"}
            category={"industry"}
            filters={["Healthcare & Fitness"]}
          />
          <WorkSlider
            title={"Consumer Durables"}
            category={"industry"}
            filters={["Consumer Durables"]}
          />
          <WorkSlider
            title={"Travel"}
            category={"industry"}
            filters={["Travel"]}
          /> */}
        </div>
      )}
    </div>
  );
};

export default Works;
