import { api } from "../api";

export const workApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getWorks: builder.query({
      query: ({ query }) => ({
        url: `/works/?${query}&populate=*`,
        method: "GET",
      }),
      transformResponse: (response, meta, arg) => response,
    }),
    getRelatedWorks: builder.query({
      query: ({ slug }) => ({
        url: `/works/?filters[slug][$ne]=${slug}&pagination[page]=1&pagination[pageSize]=3&populate=*&randomSort=true`,
        method: "GET",
      }),
      transformResponse: (response, meta, arg) => response.data,
    }),
    getWork: builder.query({
      query: ({ slug }) => ({
        url: `/works/?filters[slug][$eq]=${slug}&populate=*`,
        method: "GET",
      }),
      transformResponse: (response, meta, arg) => response.data[0],
    }),
    getIndustries: builder.query({
      query: () => ({
        url: "/industries?sort=rank:asc",
        method: "GET",
      }),
      transformResponse: (response, meta, arg) => response.data,
    }),
    getCompanies: builder.query({
      query: () => ({
        url: "/companies",
        method: "GET",
      }),
      transformResponse: (response, meta, arg) => response.data,
    }),
    getServices: builder.query({
      query: () => ({
        url: "/services?sort=rank:asc",
        method: "GET",
      }),
      transformResponse: (response, meta, arg) => response.data,
    }),
  }),
});

export const {
  useGetWorksQuery,
  useGetWorkQuery,
  useGetRelatedWorksQuery,
  useGetCompaniesQuery,
  useGetIndustriesQuery,
  useGetServicesQuery,
} = workApi;
