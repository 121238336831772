import React from "react";
import Dooj from "../assets/images/Dooj-new.jpg";
import Gals from "../assets/images/gals.jpg";
import Khushboo from "../assets/images/Khushboo.jpg";
import Nikole from "../assets/images/Nicole.jpg";
// import Renne from "../assets/images/renne.jpg";
import Rikki from "../assets/images/Rikki.jpg";
import Michell from "../assets/images/Michell-new.jpg";
import Prerna from "../assets/images/Prerna.jpg";
import Dia from "../assets/images/Dia.jpg";
import Jana from "../assets/images/Peoplewebsite/Jana.jpg";
import Yogesh from "../assets/images/Yogesh.jpg";
import Aditi from "../assets/images/Peoplewebsite/Aditi.jpg";
import Amer from "../assets/images/Peoplewebsite/Amer.jpg";
import Amit from "../assets/images/Peoplewebsite/Amit.jpg";
import Anandita from "../assets/images/Peoplewebsite/Anandita.jpg";
import Anuj from "../assets/images/Peoplewebsite/Anuj.jpg";
import Bhavna from "../assets/images/Peoplewebsite/Bhavna.jpg";
import Eva from "../assets/images/Peoplewebsite/Eva.jpg";
import Hetali from "../assets/images/Peoplewebsite/Hetali.jpg";
import Mayur from "../assets/images/Peoplewebsite/Mayur.jpg";
import Nicole from "../assets/images/Peoplewebsite/Nicole.jpg";
import Parth from "../assets/images/Peoplewebsite/Parth.jpg";
import Priya from "../assets/images/Peoplewebsite/Priya.jpg";
import Rahul from "../assets/images/Peoplewebsite/Rahul.jpg";
import Rajesh from "../assets/images/Peoplewebsite/Rajesh.jpg";
import Ravi from "../assets/images/Peoplewebsite/Ravi-Hande.jpg";
import Richa from "../assets/images/Peoplewebsite/Richa.jpg";
import Riya from "../assets/images/Peoplewebsite/Riya.jpg";
import Sadhvi from "../assets/images/Peoplewebsite/Sadhvi.jpg";
import Sagar from "../assets/images/Peoplewebsite/Sagar.jpg";
import Sanjay from "../assets/images/Peoplewebsite/Sanjay.jpg";
import Shweta from "../assets/images/Peoplewebsite/Shweta.jpg";
import Simran from "../assets/images/Peoplewebsite/Simran.jpg";
import Sweety from "../assets/images/Peoplewebsite/Sweety.jpg";
import Renee from "../assets/images/Peoplewebsite/renne.jpg";
import ArrowPng from "../assets/images/ArrowWork.png";
import { Link } from "react-router-dom";

function People() {
  return (
    <div id="people">
      <div className="people-wrapper container">
        <div className="people-head-wrap">
          <h2 className="contact-heading">People</h2>
          <p>
            Ideas shape the world,
            <br /> our people shape ideas
          </p>
        </div>
        <div className="people-card-wrapper">
          {/* dooj */}

          <Link
            target="_blank"
            to="https://in.linkedin.com/in/doojramchandani"
            className="people-card  "
          >
            <div className="people-info reverse-mobile">
              <p className="designation">Co-Founder & CEO</p>
              <h2 className="name">
                Dooj <br /> Ramchandani{" "}
                <span className="arrow-png">
                  <img src={ArrowPng} alt="arrow" />
                </span>{" "}
              </h2>
            </div>
            <div className="img-wrap">
              <img src={Dooj} alt="name-people" />{" "}
            </div>
          </Link>

          {/* rikki */}
          <Link
            target="_blank"
            to="https://in.linkedin.com/in/rikki-agarwal-79ab6926"
            className="people-card reverse-mobile"
          >
            <div className="people-info">
              <p className="designation">Co-Founder & COO</p>
              <h2 className="name">
                Rikki <br /> Agarwal{" "}
                <span className="arrow-png">
                  <img src={ArrowPng} alt="arrow" />
                </span>{" "}
              </h2>
            </div>
            <div className="img-wrap">
              <img src={Rikki} alt="name-people" />
            </div>
          </Link>

          <Link target="_blank" to="" className="people-card reverse">
            <div className="people-info">
              <p className="designation"> Chief Financial Officer</p>
              <h2 className="name">
                Amit <br /> Shah{" "}
                <span className="arrow-png">
                  <img src={ArrowPng} alt="arrow" />
                </span>{" "}
              </h2>
            </div>
            <div className="img-wrap">
              <img src={Amit} alt="name-people" />
            </div>
          </Link>

          <Link
            target="_blank"
            to=""
            className="people-card reverse reverse-mobile"
          >
            <div className="people-info">
              <p className="designation"> Company Secretary</p>
              <h2 className="name">
                Rahul <br /> Agrawal{" "}
                <span className="arrow-png">
                  <img src={ArrowPng} alt="arrow" />
                </span>{" "}
              </h2>
            </div>
            <div className="img-wrap">
              <img src={Rahul} alt="name-people" />
            </div>
          </Link>

          {/* dia */}
          <Link
            to="https://in.linkedin.com/in/dia-kirpalani-84168612"
            target="_blank"
            className="people-card "
          >
            <div className="people-info">
              <p className="designation">VP, Head of Strategy</p>

              <h2 className="name">
                Dia <br /> Kirpalani{" "}
                <span className="arrow-png">
                  <img src={ArrowPng} alt="arrow" />
                </span>{" "}
              </h2>
            </div>
            <div className="img-wrap">
              <img src={Dia} alt="name-people" />
            </div>
          </Link>

          {/* renne */}
          <Link
            to="https://www.linkedin.com/in/nicole-ferraz-9bb88421/"
            target="_blank"
            className="people-card  reverse-mobile"
          >
            <div className="people-info">
              <p className="designation">Executive Creative Director</p>

              <h2 className="name">
                Nicole <br /> Ferraz{" "}
                <span className="arrow-png">
                  <img src={ArrowPng} alt="arrow" />
                </span>{" "}
              </h2>
            </div>
            <div className="img-wrap">
              <img src={Nicole} alt="name-people" />
            </div>
          </Link>

          {/* Nicole */}
          <Link
            to="https://www.linkedin.com/in/renee-mitra-945b4477/"
            target="_blank"
            className="people-card reverse "
          >
            <div className="people-info">
              <p className="designation"> Vice President, Client Services</p>

              <h2 className="name">
                Renee <br />
                Mitra{" "}
                <span className="arrow-png">
                  <img src={ArrowPng} alt="arrow" />
                </span>{" "}
              </h2>
            </div>
            <div className="img-wrap">
              <img src={Renee} alt="name-people" />
            </div>
          </Link>

          {/* Amer */}
          <Link
            to=" https://www.linkedin.com/in/amer-ahmad-66a193bb/"
            className="people-card reverse reverse-mobile"
          >
            <div className="people-info">
              <p className="designation"> Director, Technology</p>
              <h2 className="name">
                {" "}
                Amer <br /> Ahmad{" "}
                <span className="arrow-png">
                  <img src={ArrowPng} alt="arrow" />
                </span>{" "}
              </h2>
            </div>
            <div className="img-wrap">
              <img src={Amer} alt="name-people" />
            </div>
          </Link>

          {/* sadhvi */}
          <Link
            to="https://www.linkedin.com/in/sadhvi-dhawan/"
            className="people-card"
          >
            <div className="people-info">
              <p className="designation"> Vice President, Media</p>
              <h2 className="name">
                {" "}
                Sadhvi <br /> Dhawan{" "}
                <span className="arrow-png">
                  <img src={ArrowPng} alt="arrow" />
                </span>{" "}
              </h2>
            </div>
            <div className="img-wrap">
              <img src={Sadhvi} alt="name-people" />
            </div>
          </Link>

          {/* khushboo */}
          <Link
            to="https://in.linkedin.com/in/khushboo-bhatt-0b80785a"
            target="_blank"
            className="people-card reverse-mobile"
          >
            <div className="people-info">
              <p className="designation">AVP - Creative Growth</p>
              <h2 className="name">
                Khushboo <br /> Bhatt{" "}
                <span className="arrow-png">
                  <img src={ArrowPng} alt="arrow" />
                </span>{" "}
              </h2>
            </div>
            <div className="img-wrap">
              <img src={Khushboo} alt="name-people" />
            </div>
          </Link>
          {/* michell */}
          <Link
            to="https://in.linkedin.com/in/michell-anthony-416b1a28"
            target="_blank"
            className="people-card reverse"
          >
            <div className="people-info">
              <p className="designation">Director - Human Resources</p>
              <h2 className="name">
                Michell <br /> Anthony{" "}
                <span className="arrow-png">
                  <img src={ArrowPng} alt="arrow" />
                </span>{" "}
              </h2>
            </div>
            <div className="img-wrap">
              <img src={Michell} alt="name-people" />
            </div>
          </Link>

          {/* Mayur */}
          <Link
            to="https://www.linkedin.com/in/mayur-bet-8885a028/"
            target="_blank"
            className="people-card reverse reverse-mobile"
          >
            <div className="people-info">
              <p className="designation">Creative Production Director</p>
              <h2 className="name">
                Mayur <br /> Bet{" "}
                <span className="arrow-png">
                  <img src={ArrowPng} alt="arrow" />
                </span>{" "}
              </h2>
            </div>
            <div className="img-wrap">
              <img src={Mayur} alt="name-people" />
            </div>
          </Link>

          <Link
            to="https://in.linkedin.com/in/yogesh-shirke-85317a227"
            target="_blank"
            className="people-card "
          >
            <div className="people-info">
              <p className="designation"> Creative Director </p>
              <h2 className="name">
                Yogesh <br /> Shirke{" "}
                <span className="arrow-png">
                  <img src={ArrowPng} alt="arrow" />
                </span>{" "}
              </h2>
            </div>
            <div className="img-wrap">
              <img src={Yogesh} alt="name-people" />
            </div>
          </Link>

          {/* Jana */}
          <Link
            to="https://www.linkedin.com/in/jana-colaco-5080259a/"
            target="_blank"
            className="people-card reverse-mobile"
          >
            <div className="people-info">
              <p className="designation">Creative Director</p>
              <h2 className="name">
                Jana <br /> Colaco{" "}
                <span className="arrow-png">
                  <img src={ArrowPng} alt="arrow" />
                </span>{" "}
              </h2>
            </div>
            <div className="img-wrap">
              <img src={Jana} alt="name-people" />
            </div>
          </Link>

          {/* yogesh */}

          {/* prerna */}
          <Link
            to="https://in.linkedin.com/in/prerana-parab-2b5b2666"
            target="_balnk"
            className="people-card reverse"
          >
            <div className="people-info">
              <p className="designation">Associate Creative Director</p>
              <h2 className="name">
                Prerana <br /> Parab{" "}
                <span className="arrow-png">
                  <img src={ArrowPng} alt="arrow" />
                </span>{" "}
              </h2>
            </div>
            <div className="img-wrap">
              <img src={Prerna} alt="name-people" />
            </div>
          </Link>
          {/* Bhavna */}
          <Link
            to="https://www.linkedin.com/in/bhavna-sehgal-04/"
            target="_balnk"
            className="people-card reverse reverse-mobile"
          >
            <div className="people-info">
              <p className="designation">
                Associate Director, Creative Strategy{" "}
              </p>
              <h2 className="name">
                Bhavna <br /> Sehgal{" "}
                <span className="arrow-png">
                  <img src={ArrowPng} alt="arrow" />
                </span>{" "}
              </h2>
            </div>
            <div className="img-wrap">
              <img src={Bhavna} alt="name-people" />
            </div>
          </Link>

          {/* Anuj */}
          <Link
            to="https://www.linkedin.com/in/anuj-rathod-b913067b/"
            target="_balnk"
            className="people-card "
          >
            <div className="people-info">
              <p className="designation">Associate Creative Director </p>
              <h2 className="name">
                Anuj <br /> Rathod{" "}
                <span className="arrow-png">
                  <img src={ArrowPng} alt="arrow" />
                </span>{" "}
              </h2>
            </div>
            <div className="img-wrap">
              <img src={Anuj} alt="name-people" />
            </div>
          </Link>

          {/* sweta */}
          <Link
            to="https://www.linkedin.com/in/shweta-jain-538b0913/"
            target="_balnk"
            className="people-card reverse-mobile"
          >
            <div className="people-info">
              <p className="designation">Sr. Media Director </p>
              <h2 className="name">
                Shweta <br /> Jain{" "}
                <span className="arrow-png">
                  <img src={ArrowPng} alt="arrow" />
                </span>{" "}
              </h2>
            </div>
            <div className="img-wrap">
              <img src={Shweta} alt="name-people" />
            </div>
          </Link>

          {/* Anandita */}
          <Link
            to="https://www.linkedin.com/in/anindita-saha-75a835180/"
            target="_balnk"
            className="people-card reverse"
          >
            <div className="people-info">
              <p className="designation">Media Director </p>
              <h2 className="name">
                Anindita <br /> Saha{" "}
                <span className="arrow-png">
                  <img src={ArrowPng} alt="arrow" />
                </span>{" "}
              </h2>
            </div>
            <div className="img-wrap">
              <img src={Anandita} alt="name-people" />
            </div>
          </Link>

          {/* sweety */}
          <Link
            to="https://www.linkedin.com/in/sweetyshah18/"
            target="_balnk"
            className="people-card reverse reverse-mobile"
          >
            <div className="people-info">
              <p className="designation">
                Senior Manager, Performance Marketing{" "}
              </p>
              <h2 className="name">
                Sweety <br /> Shah{" "}
                <span className="arrow-png">
                  <img src={ArrowPng} alt="arrow" />
                </span>{" "}
              </h2>
            </div>
            <div className="img-wrap">
              <img src={Sweety} alt="name-people" />
            </div>
          </Link>

          {/* ravindra */}
          <Link to=" " target="_balnk" className="people-card ">
            <div className="people-info">
              <p className="designation">Director - Finance</p>
              <h2 className="name">
                Ravindra <br /> Hande{" "}
                <span className="arrow-png">
                  <img src={ArrowPng} alt="arrow" />
                </span>{" "}
              </h2>
            </div>
            <div className="img-wrap">
              <img src={Ravi} alt="name-people" />
            </div>
          </Link>

          {/* sanjay */}
          <Link
            to="https://www.linkedin.com/in/sanjay-vishwakarma-36b1a5100/"
            target="_balnk"
            className="people-card reverse-mobile"
          >
            <div className="people-info">
              <p className="designation">
                Finance Executive, Media Reconciliation and Billing
              </p>
              <h2 className="name">
                Sanjay <br /> Vishwakarma{" "}
                <span className="arrow-png">
                  <img src={ArrowPng} alt="arrow" />
                </span>{" "}
              </h2>
            </div>
            <div className="img-wrap">
              <img src={Sanjay} alt="name-people" />
            </div>
          </Link>

          {/* simran */}
          <Link
            to="https://www.linkedin.com/in/simran-agarwal-29b171235/"
            target="_balnk"
            className="people-card reverse"
          >
            <div className="people-info">
              <p className="designation">Associate Client Services Director</p>
              <h2 className="name">
                Simran <br /> Agarwal{" "}
                <span className="arrow-png">
                  <img src={ArrowPng} alt="arrow" />
                </span>{" "}
              </h2>
            </div>
            <div className="img-wrap">
              <img src={Simran} alt="name-people" />
            </div>
          </Link>

          {/* richa */}
          <Link
            to="https://www.linkedin.com/in/richa-bansal-a8b49b48/"
            target="_balnk"
            className="people-card reverse reverse-mobile"
          >
            <div className="people-info">
              <p className="designation">Associate Client Services Director</p>
              <h2 className="name">
                Richa <br /> Bansal{" "}
                <span className="arrow-png">
                  <img src={ArrowPng} alt="arrow" />
                </span>{" "}
              </h2>
            </div>
            <div className="img-wrap">
              <img src={Richa} alt="name-people" />
            </div>
          </Link>

          {/* aditi */}
          <Link
            to="https://www.linkedin.com/in/aditi-deochake-069051104/"
            target="_balnk"
            className="people-card "
          >
            <div className="people-info">
              <p className="designation">Associate Client Services Director</p>
              <h2 className="name">
                Aditi <br /> Deochake{" "}
                <span className="arrow-png">
                  <img src={ArrowPng} alt="arrow" />
                </span>{" "}
              </h2>
            </div>
            <div className="img-wrap">
              <img src={Aditi} alt="name-people" />
            </div>
          </Link>

          {/* riya */}
          <Link
            to="https://www.linkedin.com/in/riya-chheda-8046b613b/"
            target="_balnk"
            className="people-card reverse-mobile"
          >
            <div className="people-info">
              <p className="designation">
                Manager, Human Resource Business Partner
              </p>
              <h2 className="name">
                Riya <br /> Chheda{" "}
                <span className="arrow-png">
                  <img src={ArrowPng} alt="arrow" />
                </span>{" "}
              </h2>
            </div>
            <div className="img-wrap">
              <img src={Riya} alt="name-people" />
            </div>
          </Link>

          {/* eva */}
          <Link
            to="https://www.linkedin.com/in/evangeline-joseph-83a693a9/"
            target="_balnk"
            className="people-card reverse"
          >
            <div className="people-info">
              <p className="designation">
                Manager, Human Resource Business Partner
              </p>
              <h2 className="name">
                Evangeline <br /> Joseph{" "}
                <span className="arrow-png">
                  <img src={ArrowPng} alt="arrow" />
                </span>{" "}
              </h2>
            </div>
            <div className="img-wrap">
              <img src={Eva} alt="name-people" />
            </div>
          </Link>

          {/* hetali */}
          <Link
            to="https://www.linkedin.com/in/hetalisoni/"
            target="_balnk"
            className="people-card reverse reverse-mobile"
          >
            <div className="people-info">
              <p className="designation">Sr. Media Planner</p>
              <h2 className="name">
                Hetali <br /> Soni{" "}
                <span className="arrow-png">
                  <img src={ArrowPng} alt="arrow" />
                </span>{" "}
              </h2>
            </div>
            <div className="img-wrap">
              <img src={Hetali} alt="name-people" />
            </div>
          </Link>

          {/* sagar */}
          <Link
            to="https://www.linkedin.com/in/sagarsawant4224/"
            target="_balnk"
            className="people-card "
          >
            <div className="people-info">
              <p className="designation">Media Manager</p>
              <h2 className="name">
                Sagar <br /> Sawant{" "}
                <span className="arrow-png">
                  <img src={ArrowPng} alt="arrow" />
                </span>{" "}
              </h2>
            </div>
            <div className="img-wrap">
              <img src={Sagar} alt="name-people" />
            </div>
          </Link>

          {/* Parth */}
          <Link
            to="https://www.linkedin.com/in/parth-jadia-024a1b152/"
            target="_balnk"
            className="people-card reverse-mobile"
          >
            <div className="people-info">
              <p className="designation">
                Sr. Manager, Communications & Alliances
              </p>
              <h2 className="name">
                Parth <br /> Jadia{" "}
                <span className="arrow-png">
                  <img src={ArrowPng} alt="arrow" />
                </span>{" "}
              </h2>
            </div>
            <div className="img-wrap">
              <img src={Parth} alt="name-people" />
            </div>
          </Link>
          {/* Parth */}
          <Link to=" " target="_balnk" className="people-card reverse">
            <div className="people-info">
              <p className="designation">Finance Controller</p>
              <h2 className="name">
                Rajesh <br /> Gupta{" "}
                <span className="arrow-png">
                  <img src={ArrowPng} alt="arrow" />
                </span>{" "}
              </h2>
            </div>
            <div className="img-wrap">
              <img src={Rajesh} alt="name-people" />
            </div>
          </Link>

          {/* Parth */}
          <Link
            to="https://www.linkedin.com/in/ca-priya-ajey-mishra-95ba0266/"
            target="_balnk"
            className="people-card reverse reverse-mobile"
          >
            <div className="people-info">
              <p className="designation">Internal Auditor</p>
              <h2 className="name">
                CA Priya <br /> Mishra{" "}
                <span className="arrow-png">
                  <img src={ArrowPng} alt="arrow" />
                </span>{" "}
              </h2>
            </div>
            <div className="img-wrap">
              <img src={Priya} alt="name-people" />
            </div>
          </Link>

          {/* yogesh
          {/* <div className="people-card">
            <div className="people-info">
              <p className="designation"> Creative Director </p>
              <h2 className="name">
                Jana <br /> Colaco{" "}
                <span className="arrow-png">
                  <img src={ArrowPng} alt="arrow" />
                </span>{" "}
              </h2>
            </div>
            <div className="img-wrap">
              <img src={Jana} alt="name-people" />
            </div>
          </div> */}

          {/* michell */}
        </div>
      </div>
    </div>
  );
}

export default People;
