// src/BuildEcom.js
import React from "react";
// import "../BlinkEcomm/blinkecom-style/BlinkEcom.scss";

import Web from "../assets/images/blinkecom-images/ICONS/Web.png";
import Perfo from "../assets/images/blinkecom-images/ICONS/Performance.png";
import Reel from "../assets/images/blinkecom-images/ICONS/Reel.png";
import Mentor from "../assets/images/blinkecom-images/ICONS/Mentor.png";
import Social from "../assets/images/blinkecom-images/ICONS/SocialMedia.png";
import Analysis from "../assets/images/blinkecom-images/ICONS/analysis.png";
import Logo from "../assets/images/blinkecom-images/blink.png";

function BuildEcom() {
  return (
    <main className="build-ecomm-page-css">
      <section className="banner">
        <div className="wrapper container_ecom">
          <div className="logo-section">
            <div className="hero-copy">
              <h2>Build Big Ecomm Brand</h2>
              <h2>with Blink Digital</h2>
            </div>
            <a className="btn" href="#form_section">
              Register Now
            </a>
          </div>
        </div>
      </section>

      <section className="why_us">
        <div className="wrapper container-1">
          <h2>Here's How We Help</h2>
          <div className="flex-logo-wrapper">
            <div className="logo-copy-item">
              <img src={Web} alt="" />
              <p>Optimised Landing Page for Higher Conversion Rates</p>
            </div>
            <div className="logo-copy-item">
              <img src={Perfo} alt="" />
              <p>Creative first Performance Marketing</p>
            </div>
            <div className="logo-copy-item">
              <img src={Reel} alt="" />
              <p>User-Generated Content (UGC) Reels</p>
            </div>
            <div className="logo-copy-item">
              <img src={Mentor} alt="" />
              <p>Mentorship Events with Meta & Google</p>
            </div>
            <div className="logo-copy-item">
              <img src={Social} alt="" />
              <p>Building Social Presence</p>
            </div>
            <div className="logo-copy-item">
              <img src={Analysis} alt="" />
              <p>Measurement & Analysis</p>
            </div>
          </div>
        </div>
      </section>

      <section className="details" id="form_section">
        <div className="wrapper container_ecom">
          <h2>Enter your details</h2>
          <div style={{ backgroundColor: "transparent" }}>
            <iframe
              src="https://forms.gle/bBfnYZdu5pXZbsMQ6"
              width="1000"
              height="1118"
              frameBorder="0"
              marginHeight="0"
              marginWidth="0"
              style={{ border: "none", backgroundColor: "transparent" }}
              title="Google Form"
            >
              Loading…
            </iframe>
          </div>
        </div>
      </section>

      <footer className="footer-ecom">
        <div className="wrapper container_ecom">
          <div className="logo">
            <p>For more details:</p>
            <a
              href="https://blinkdigital.in/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={Logo} alt="Blink Digital" />
            </a>
          </div>
          <div className="email_wrap">
            <p className="hidden-lg">Contact :</p>
            <div className="flex-footer">
              <a href="mailto:rikki@blinkdigital.in">rikki@blinkdigital.in</a>
              <span className="hidden-xs">|</span>
              <a href="mailto:sadhvi@blinkdigital.in">sadhvi@blinkdigital.in</a>
              <span className="hidden-xs">|</span>
              <a href="mailto:shweta.b@blinkdigital.in">
                shweta.b@blinkdigital.in
              </a>
            </div>
          </div>
        </div>
      </footer>
    </main>
  );
}

export default BuildEcom;
