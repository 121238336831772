import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/autoplay";
import "swiper/css/navigation";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
// import "./css/ads.scss"; // Create a separate CSS file for styles

import Logo from "../assets/images/ad-images/Layer_1.svg";

import Pedal from "../assets/images/ad-images/logos/pedal.svg";
import Skinny from "../assets/images/ad-images/logos/skinny.svg";
import Wegn from "../assets/images/ad-images/logos/wegenerate.svg";
import Hen from "../assets/images/ad-images/logos/hen.svg";
import WeSolve from "../assets/images/ad-images/logos/wesolve.png";
import Enha from "../assets/images/ad-images/logos/enhance.svg";

import BackDots from "../assets/images/ad-images/backdots.png";
import ArrowRight from "../assets/images/ad-images/arrowRight.svg";
import sliderBack from "../assets/images/ad-images/sliderback.png";
import BlinkLogo from "../assets/images/ad-images/whiteBlinkLogo.png";

import One from "../assets/images/ad-images/o1.svg";
import Gmail from "../assets/images/ad-images/gmail.svg";
import Doller from "../assets/images/ad-images/doller.svg";
import Call from "../assets/images/ad-images/call.svg";

import onepng from "../assets/images/ad-images/01.png";
import twopng from "../assets/images/ad-images/02.png";
import threepng from "../assets/images/ad-images/03.png";
import fourpng from "../assets/images/ad-images/04.png";
import fivepng from "../assets/images/ad-images/05.png";

gsap.registerPlugin(ScrollTrigger);

const AddAccounts = () => {
  const [openAccordion, setOpenAccordion] = useState(null);

  const [openIndex, setOpenIndex] = useState(null);

  // Accordion toggle function
  const toggleAccordion = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };
  // Swiper settings
  const swiper1Settings = {
    slidesPerView: "auto",
    spaceBetween: 100,
    loop: true,
    autoplay: {
      delay: 0,
      disableOnInteraction: false,
    },
    speed: 10000,
  };

  const swiper2Settings = {
    slidesPerView: 1,
    spaceBetween: 50,
    loop: true,
    navigation: true,
    speed: 700,
    breakpoints: {
      768: {
        slidesPerView: 1,
        spaceBetween: 20,
        speed: 500,
      },
    },
  };

  // Accordion toggle function

  useEffect(() => {
    const textSlides = document.querySelectorAll(".text-slide");
    const imageSlides = document.querySelectorAll(".image-slide");

    // GSAP Timeline with adjusted scrub and stagger settings
    const timeline = gsap.timeline({
      scrollTrigger: {
        trigger: ".scroll-section",
        start: "top top",
        end: "+=300%",
        pin: true,
        scrub: 1.5, // Increased for smoother effect
      },
    });

    // Animate text slides with staggered start times
    textSlides.forEach((slide, i) => {
      timeline
        .fromTo(
          slide,
          { y: "50%", opacity: 0 },
          { y: "0%", opacity: 1, duration: 0.8, ease: "power2.inOut" },
          i * 0.7 // Adjusted to avoid overlapping too much
        )
        .to(
          slide,
          { y: "-50%", opacity: 0, duration: 0.8, ease: "power2.inOut" },
          (i + 0.5) * 0.7
        );
    });

    // Animate image slides with the same stagger
    imageSlides.forEach((slide, i) => {
      timeline
        .fromTo(
          slide,
          { opacity: 0 },
          { opacity: 1, duration: 0.8, ease: "power2.inOut" },
          i * 0.7
        )
        .to(
          slide,
          { opacity: 0, duration: 0.8, ease: "power2.inOut" },
          (i + 0.5) * 0.7
        );
    });
  }, []);

  const swiperSettings = {
    slidesPerView: 1,
    spaceBetween: 50,
    loop: false,
    navigation: {
      nextEl: ".swiper-button-next", // Custom class for next arrow
      prevEl: ".swiper-button-prev", // Custom class for prev arrow
    },
    // autoplay: {
    //   delay: 5000,
    //   disableOnInteraction: false,
    // },
  };

  const accordionData = [
    {
      question: "Do you provide agency Ad accounts?",
      answer:
        "Yes, we provide premium agency accounts from the same business managers as that of KFC India, Amazon Prime Video, and other bigger brands.",
    },
    {
      question: "How to get started?",
      answer: "Just reach out to us via email, WhatsApp, or Telegram.",
    },
    {
      question: "How does it work?",
      answer:
        "You’ll have to reach out to us on any communication channel. Our team will audit your landing page and creatives to provide you with a custom solution.",
    },
    {
      question: "How much time does it take to get started?",
      answer: "20 mins to 1 hr at max after receiving the payment.",
    },
    {
      question: "What does it cost?",
      answer: "We’ll only charge you a percentage based on your ad spends.",
    },
    {
      question: "Do you work with all types of businesses?",
      answer: "Yes, we do.",
    },
    {
      question: "Can I add my own card to pay for the ad spends?",
      answer: "Yes, you can.",
    },
  ];

  return (
    <div className="add-accounts-page-css">
      {/* Header */}
      <header>
        <nav className="container-ad">
          <img className="logo" src={Logo} alt="Logo" />
          <a
            href="https://api.whatsapp.com/send/?phone=918368374237&text&type=phone_number&app_absent=0"
            className="getin-btn"
          >
            Get in Touch
          </a>
        </nav>
      </header>

      {/* Hero Section */}
      <main>
        <section id="home">
          <h1 className="hero-copy container-ad">
            Get Best Stable agency account <br /> infrastructure in the world
          </h1>
          <div className="services container-ad">
            <div className="wrapper">
              <div className="orange-ring">
                <img src={One} alt="Zero bans" />
              </div>
              <h4 className="copy">
                Zero
                <br />
                bans
              </h4>
            </div>

            <div className="wrapper">
              <div className="orange-ring">
                <img src={Gmail} alt="Stable ad accounts" />
              </div>
              <h4 className="copy">
                Stable ad
                <br />
                accounts
              </h4>
            </div>

            <div className="wrapper">
              <div className="orange-ring">
                <img src={Doller} alt="Unlimited spends" />
              </div>
              <h4 className="copy">
                Unlimited
                <br />
                spends
              </h4>
            </div>

            <div className="wrapper">
              <div className="orange-ring">
                <img src={Call} alt="24x7 support" />
              </div>
              <h4 className="copy">
                24x7
                <br />
                support
              </h4>
            </div>
          </div>

          {/* Contact Us Button */}
          <div className="homepage-btn">
            <a
              href="https://api.whatsapp.com/send/?phone=918368374237&text&type=phone_number&app_absent=0"
              className="getin-btn"
            >
              Contact Us
            </a>
          </div>
        </section>

        {/* About Section with Swiper */}
        <section id="about">
          <Swiper
            {...swiper1Settings}
            className="swiper-container swiper1"
            modules={[Autoplay]}
          >
            <SwiperSlide>
              <img src={Pedal} alt="Logo 1" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={Skinny} alt="Logo 2" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={Wegn} alt="Logo 3" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={Hen} alt="Logo 4" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={Pedal} alt="Logo 5" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={WeSolve} alt="Logo 6" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={Enha} alt="Logo 7" />
            </SwiperSlide>
          </Swiper>
          <div className="hero-img">
            <img className="dot-img" src={BackDots} alt="" />
          </div>
        </section>

        {/* Cards Section */}
        <div className="scroll-container">
          <section className="scroll-section container-ad">
            <div className="pin-section">
              {/* Text Slides */}
              <div className="text-container">
                <div className="text-slide">
                  <div className="card-copy">
                    <h4 className="card-title">Meta Agency Account</h4>
                    <p className="card-discription">No Ad Spend Limits</p>
                    <p className="card-discription">
                      Access to Beta features like Bid multiplier
                    </p>
                    <p className="card-discription">
                      Unlock Call back on call ads and 60s call optimization
                    </p>
                    <p className="card-discription">Attach your own card</p>
                    <p className="card-discription">24 X 7 Support</p>
                    <a href="#" className="learnmore-btn">
                      <span>Learn More</span>
                      <img src={ArrowRight} alt="arrow" />
                    </a>
                  </div>
                </div>

                <div className="text-slide">
                  <div className="card-copy">
                    <h4 className="card-title">Google Ad Accounts</h4>
                    <p className="card-discription">No Ad Spend Limits</p>
                    <p className="card-discription">Attach your own card</p>
                    <p className="card-discription">24 X 7 Support</p>
                    <p className="card-discription">Better CPM and CPA’s</p>
                    <p className="card-discription">
                      Avail Up to 2.3% Cashback (card accounts)
                    </p>
                    <a href="#" className="learnmore-btn">
                      <span>Learn More</span>
                      <img src={ArrowRight} alt="arrow" />
                    </a>
                  </div>
                </div>

                <div className="text-slide">
                  <div className="card-copy">
                    <h4 className="card-title">Tiktok Ad Accounts</h4>
                    <p className="card-discription">No Ad Spend Limits</p>
                    <p className="card-discription">Attach your own card</p>
                    <p className="card-discription">24 X 7 Support</p>
                    <p className="card-discription">Better CPM and CPA’s</p>
                    <p className="card-discription">
                      Avail Up to 2.3% Cashback (card accounts)
                    </p>
                    <a href="#" className="learnmore-btn">
                      <span>Learn More</span>
                      <img src={ArrowRight} alt="arrow" />
                    </a>
                  </div>
                </div>

                <div className="text-slide">
                  <div className="card-copy">
                    <h4 className="card-title">Taboola Ad Accounts</h4>
                    <p className="card-discription">No Ad Spend Limits</p>
                    <p className="card-discription">Attach your own card</p>
                    <p className="card-discription">24 X 7 Support</p>
                    <p className="card-discription">Better CPM and CPA’s</p>
                    <p className="card-discription">
                      Avail Up to 2.3% Cashback (card accounts)
                    </p>
                    <a href="#" className="learnmore-btn">
                      <span>Learn More</span>
                      <img src={ArrowRight} alt="arrow" />
                    </a>
                  </div>
                </div>

                <div className="text-slide">
                  <div className="card-copy">
                    <h4 className="card-title">Snap Ad Accounts</h4>
                    <p className="card-discription">No Ad Spend Limits</p>
                    <p className="card-discription">Attach your own card</p>
                    <p className="card-discription">24 X 7 Support</p>
                    <p className="card-discription">Better CPM and CPA’s</p>
                    <p className="card-discription">
                      Avail Up to 2.3% Cashback (card accounts)
                    </p>
                    <a href="#" className="learnmore-btn">
                      <span>Learn More</span>
                      <img src={ArrowRight} alt="arrow" />
                    </a>
                  </div>
                </div>
              </div>

              {/* Image Slides */}
              <div className="image-container">
                <div className="image-slide backmeta"></div>
                <div className="image-slide google-ads"></div>
                <div className="image-slide tiktok"></div>
                <div className="image-slide tabola"></div>
                <div className="image-slide snap"></div>
              </div>
            </div>
          </section>
        </div>

        <section id="sliderContainer">
          <h2 className="heading-slider-2">What our clients say about us</h2>
          <div className="services-wrapper container-ad">
            <div className="wrapper">
              <img src={sliderBack} alt="Slider Background" />
              <Swiper
                {...swiperSettings}
                className="swiper-container swiper2"
                modules={[Navigation, Autoplay]}
              >
                <SwiperSlide>
                  <h4 className="heading">Best Solid Accounts</h4>
                  <p className="para">
                    This is the most solid Google Ads agency account I ever got.
                    The service is reliable, and they are very reactive with the
                    budget. I suggest you search nowhere else; they got
                    everything you need!
                  </p>
                  <h4 className="city">Tom Fitzgerald</h4>
                </SwiperSlide>

                <SwiperSlide>
                  <h4 className="heading">Excellent Service</h4>
                  <p className="para">
                    Excellent service, always available and kind. Their accounts
                    are reliable, and they solve any issues promptly.
                  </p>
                  <h4 className="city">Eliza Bucataru</h4>
                </SwiperSlide>

                <SwiperSlide>
                  <h4 className="heading">Great Customer Service</h4>
                  <p className="para">
                    Customer service is great! Sometimes responses are delayed,
                    but they provide the best service overall!
                  </p>
                  <h4 className="city">Rahul Jaiswal</h4>
                </SwiperSlide>

                <SwiperSlide>
                  <h4 className="heading">Good Services & Support</h4>
                  <p className="para">
                    We have been working with Blink Digital for the past 3 years
                    and are fully satisfied with their services and support.
                  </p>
                  <h4 className="city">Vikas Pandey</h4>
                </SwiperSlide>
              </Swiper>
              {/* Custom navigation buttons */}
              <div className="swiper-button-next swiper-button-next2"></div>
              <div className="swiper-button-prev swiper-button-prev2"></div>
            </div>
          </div>
        </section>

        <section id="cardSection">
          <h2 className="heading-cardSection">Go for limitless growth</h2>
          <div className="card-container">
            {/* Top Row of Cards */}
            <div className="row top-cards">
              <div className="card">
                <img
                  width="60"
                  className="circle-pink"
                  src={onepng}
                  alt="Stable ad agency accounts"
                />
                <p className="heading-card">
                  Stable ad agency <br />
                  accounts at 0%
                </p>
                <p className="copy-card">
                  We provide an additional layer of stability to scale. We're
                  here to get you ad accounts like those of brands like KFC and
                  Amazon Prime Video.
                </p>
              </div>

              <div className="card">
                <img
                  width="60"
                  className="circle-pink"
                  src={twopng}
                  alt="Lowest CPMs and CPAs"
                />
                <p className="heading-card">
                  Lowest CPMs <br />
                  and CPAs
                </p>
                <p className="copy-card">
                  We ensure continuous hygiene checks to get you the best CPMs
                  and CPAs.
                </p>
              </div>

              <div className="card card-3">
                <img
                  width="60"
                  className="circle-pink"
                  src={threepng}
                  alt="Cashback on ad spends"
                />
                <p className="heading-card">
                  2.3% cashback <br />
                  on ad spends
                </p>
                <p className="copy-card">
                  Avail the cashback on all your spends.
                </p>
              </div>
            </div>

            {/* Center Row of Cards */}
            <div className="row center-card">
              <div className="card card-4">
                <img
                  width="60"
                  className="circle-pink"
                  src={fourpng}
                  alt="Resolve cash flow problems"
                />
                <p className="heading-card">
                  Resolve cash flow <br />
                  problems
                </p>
                <p className="copy-card">
                  Attach your own card to your accounts and have full autonomy
                  over the money.
                </p>
              </div>

              <div className="card card-5">
                <img
                  width="60"
                  className="circle-pink"
                  src={fivepng}
                  alt="Special and exclusive services"
                />
                <p className="heading-card">
                  Special and exclusive <br />
                  services
                </p>
                <p className="copy-card">
                  We go above and beyond to ensure your growth. Reach out to us
                  to avail these extra bonuses!
                </p>
              </div>
            </div>
          </div>
        </section>

        {/* FAQ Accordion Section */}
        <section className="accordion-section">
          <h2 className="heading-acc">Frequently asked questions</h2>
          <div className="accordion">
            {accordionData.map((item, index) => (
              <div className="accordion-item" key={index}>
                <button
                  className="accordion-button"
                  onClick={() => toggleAccordion(index)}
                >
                  <p className="btn-copy">{item.question}</p>
                  <span className="plus-minus">
                    {openIndex === index ? "-" : "+"}
                  </span>
                </button>
                <div
                  className={`accordion-content ${
                    openIndex === index ? "show" : ""
                  }`}
                >
                  <p>{item.answer}</p>
                </div>
              </div>
            ))}
          </div>
        </section>

        <section id="cardContact">
          <div className="wrapper container">
            <a href="">Uday@blinkdigital.in</a>
            <p>WhatsApp and Telegram: +91 8368374237</p>
            <p>Ph no. +971 522095564</p>
          </div>
        </section>
      </main>

      {/* Footer */}
      <div className="footer-ad">
        <div className="footer-wrapper-ad container-ad">
          <img className="logo" src={BlinkLogo} alt="Footer Logo" />
          <div className="footer-hero-img">
            <p className="copyright-copy">
              © Blink Digital 2023. All Rights Reserved
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddAccounts;
