import React from "react";
import { Link, Outlet, useLocation } from "react-router-dom";
import internetLogo from "../assets/images/internet.png";

function Footer() {
  const location = useLocation();
  const isHomepage = location.pathname == "/";
  const isWorks = location.pathname == "/work";
  const isNews = location.pathname == "/news";
  const isWorkPage = location.pathname == "work/:id";
  const isInitiatives = location.pathname == "/initiatives";
  const style = isWorks
    ? { borderBottom: "1px solid black" }
    : isHomepage
    ? { borderBottom: "1px solid black" }
    : { borderBottom: "1px solid black" };

  return (
    <footer className="footer-home white-footer" data-scroll-section>
      <div className="footer-wrapper container">
        <div className="internet-logo">
          <img src={internetLogo} alt="logo" />
        </div>

        <div className="footer-item ">
          <div className="footer-wrap">
            <h4 className="head">Info</h4>
            {/* <p className="link">About</p> */}
            <Link to={"/work"} className="link">
              <p>Work</p>
            </Link>
            <Link to={"/people"} className="link">
              <p>People</p>
            </Link>
            <Link to={"/initiatives"} className="link">
              <p>Initiatives</p>
            </Link>
            {/* <p className="link">Capability</p>
          <p className="link">Team</p>
          <p className="link">Awards</p> */}
            <Link to={"/contact"} className="link">
              <p>Contact</p>
            </Link>
          </div>
        </div>
        <div className="footer-item ">
          <div className="footer-wrap">
            <h4 className="head">Offices</h4>
            <p className="city">Mumbai</p>
            <p className="city">Dubai</p>
            <p className="city">Amsterdam</p>
            <p className="city">New York</p>
          </div>
        </div>

        <div className="footer-item order-3">
          <div className="footer-wrap">
            <h4 className="head">Social</h4>
            <a
              href="https://www.instagram.com/blink.digital"
              className="link"
              target="_blank"
            >
              <p> Instagram</p>
            </a>
            <a
              href="https://www.linkedin.com/company/blink-digital"
              className="link"
              target="_blank"
            >
              <p>Linkedin</p>
            </a>
            <a
              href="https://vimeo.com/blinkdigital"
              className="link"
              target="_blank"
            >
              <p>Vimeo</p>
            </a>
            <a href="" className="link" target="_blank">
              <p>X</p>
            </a>
          </div>
        </div>

        <div className="footer-item order-0">
          <div className="footer-wrap">
            <h4 className="head">General Enquiries</h4>
            <a className="email" href="mailto:info@blinkdigital.in">
              info@blinkdigital.in
            </a>
            <h4 className="head-b">New business</h4>
            <a className="email" href="mailto:newbusiness@blinkdigital.in">
              newbusiness@blinkdigital.in
            </a>
            <h4 className="head-b">Careers</h4>
            <a className="email" href="mailto:careers@blinkdigital.in">
              careers@blinkdigital.in
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
