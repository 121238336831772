import React from "react";
// import "./styles.css";

import Logo1 from "../assets/images/BlinkLogoImages/Black Wordmark_Landscape@3x.png";
import Logo2 from "../assets/images/BlinkLogoImages/Black Wordmark_Portrait@3x.png";
import Logo3 from "../assets/images/BlinkLogoImages/Black logo_Landscape 1@3x.png";
import Logo4 from "../assets/images/BlinkLogoImages/Black logo_Landscape 2@3x.png";
import Logo5 from "../assets/images/BlinkLogoImages/Black logo_Portrait@3x.png";
import Logo6 from "../assets/images/BlinkLogoImages/White Wordmark_Landscape@3x.png";
import Logo7 from "../assets/images/BlinkLogoImages/White Wordmark_Portrait@3x.png";
import Logo8 from "../assets/images/BlinkLogoImages/White logo_Landscape 1@3x.png";
import Logo9 from "../assets/images/BlinkLogoImages/White logo_Landscape 2@3x.png";
import Logo10 from "../assets/images/BlinkLogoImages/White logo_Portrait@3x.png";
import Logo11 from "../assets/images/BlinkLogoImages/Colour logo_Landscape 1@3x.png";
import Logo12 from "../assets/images/BlinkLogoImages/Colour logo_Landscape 2@3x.png";
import Logo13 from "../assets/images/BlinkLogoImages/Colour logo_Portrait@3x.png";
import Logo14 from "../assets/images/BlinkLogoImages/Wordmark_Landscape@3x.png";
import Logo15 from "../assets/images/BlinkLogoImages/Wordmark_Portrait@3x.png";
import Logo16 from "../assets/images/BlinkLogoImages/Blink Symbol_B&W@3x.png";
import Logo17 from "../assets/images/BlinkLogoImages/Blink Symbol_Colour@3x.png";
import Logo18 from "../assets/images/BlinkLogoImages/Blink Symbol_Fill white@3x.png";
import Logo19 from "../assets/images/BlinkLogoImages/Blink Symbol_Fill@3x.png";
import Logo20 from "../assets/images/BlinkLogoImages/Blink Symbol_White@3x.png";
import Banner from "../assets/images/BlinkLogoImages/Logo page banner.png";

const downloadLogo = (imageSrc, fileName) => {
  const link = document.createElement("a");
  link.href = imageSrc;
  link.download = fileName;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

const LogosPage = () => {
  return (
    <div className="logo-main-css">
      <div className="banner-relative">
        <img src={Banner} className="w-full" alt="Banner" />
        <h4 className="absolute-copy">Blink Digital Logos</h4>
      </div>

      <div className="wrapper-logo-row container">
        <div className="grid-logo">
          {[Logo17, Logo16, Logo19, Logo20, Logo18].map((logo, index) => (
            <div key={index} className="card-box">
              <div className="img-box">
                <img
                  src={logo}
                  alt={`Blink Symbol ${index + 1}`}
                  className=""
                />
              </div>
              <button
                onClick={() =>
                  downloadLogo(logo, `blink-symbol-${index + 1}.png`)
                }
                className="btn-dow"
              >
                Download
              </button>
            </div>
          ))}
        </div>

        <div className="grid-logo">
          {[Logo13, Logo11, Logo12, Logo15, Logo14].map((logo, index) => (
            <div key={index} className="card-box">
              <div className="img-box">
                <img src={logo} alt={`Colour Logo ${index + 1}`} className="" />
              </div>
              <button
                onClick={() =>
                  downloadLogo(logo, `colour-logo-${index + 1}.png`)
                }
                className="btn-dow"
              >
                Download
              </button>
            </div>
          ))}
        </div>
        <div className="grid-logo">
          {[Logo5, Logo3, Logo4, Logo2, Logo1].map((logo, index) => (
            <div key={index} className="card-box">
              <div className="img-box">
                <img src={logo} alt={`Black Logo ${index + 1}`} className="" />
              </div>
              <button
                onClick={() =>
                  downloadLogo(logo, `black-logo-${index + 1}.png`)
                }
                className="btn-dow"
              >
                Download
              </button>
            </div>
          ))}
        </div>

        <div className="grid-logo">
          {[Logo10, Logo8, Logo9, Logo7, Logo6].map((logo, index) => (
            <div key={index} className="card-box">
              <div className="img-box">
                <img src={logo} alt={`White Logo ${index + 1}`} className="" />
              </div>
              <button
                onClick={() =>
                  downloadLogo(logo, `white-logo-${index + 1}.png`)
                }
                className="btn-dow"
              >
                Download
              </button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default LogosPage;
