import React from 'react'
import DoojHeadshot from '../assets/images/dooj.jpeg'
import { useGetContactsQuery, useGetDoojDataQuery } from 'store/contact/contactApi'

function Dooj() {


    const { data: contacts, isContactsLoading } = useGetContactsQuery();

    // console.log(contacts?.attributes?.thumbnail?.data?.attributes?.url);


    return (
        <img src={contacts?.attributes?.thumbnail?.data?.attributes?.url} />
    )
}

export default Dooj